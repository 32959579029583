/* eslint react-app/import/no-webpack-loader-syntax: off */
import LOADER from '-!svg-react-loader!@assets/images/loader-1x.svg';
import LOGO_CHECKSOCIAAL from '-!svg-react-loader!@assets/images/logo-checksociaal-1x.svg';
import LOGO_VERHUURSIGNAAL from '-!svg-react-loader!@assets/images/logo-verhuursignaal-1x.svg';
import LOGO_GGN from '-!svg-react-loader!@assets/images/logo-ggn-1x.svg';
import LOGO_GGN_ALT from '-!svg-react-loader!@assets/images/logo-ggn-alt-1x.svg';
import POWERED_BY_EXPERIAN from '-!svg-react-loader!@assets/images/powered-by-experian-1x.svg';

export const VISUALS = {
	LOADER,
	LOGO_CHECKSOCIAAL,
	LOGO_VERHUURSIGNAAL,
	LOGO_GGN,
	LOGO_GGN_ALT,
	POWERED_BY_EXPERIAN,
};

export default {
	LOADER,
	LOGO_CHECKSOCIAAL,
	LOGO_VERHUURSIGNAAL,
	LOGO_GGN,
	LOGO_GGN_ALT,
	POWERED_BY_EXPERIAN,
};
