import { autorun, toJS, set } from 'mobx';

const _type_of_storage = process.env.STORAGE || 'local';
const _storage = window[`${_type_of_storage}Storage`];

export const AcAutoSave = (store, callback, key) => {
	if (!store || !key) return;

	let firstRun = true;

	autorun(() => {
		// This code will run every time any observable property
		// in the provided store is updated
		const data = JSON.stringify(toJS(store));

		if (!firstRun && callback) {
			callback(data);
		}
		firstRun = false;
	});
};

export const AcAutoLoad = (store, key) => {
	if (!store || !key) return;

	const data = AcGetState(key);

	if (data && store) set(store, { [key]: data });
};

export const AcSaveState = (key, value) => {
	if (!key || !value) return;

	_storage.setItem(key, JSON.stringify(value));
};

export const AcGetState = key => {
	if (!key) return;

	const value = _storage.getItem(key);

	if (value) return JSON.parse(value);
	return value;
};

export const AcRemoveState = key => {
	if (!key) return;

	_storage.removeItem(key);
};

export const AcClearState = () => {
	_storage.clear();
};

export default {
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcGetState,
	AcRemoveState,
	AcClearState,
};
