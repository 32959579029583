const AUTH = '/auth';
const CONTACT = '/contact';
const FORGOT_PASSWORD = '/forgot-password';
const LOGOUT = '/logout';
const OAUTH = '/oauth';
const PDF = '/pdf';
const PORTAL = '/portal';
const PROFILE = '/profile';
const RESET_PASSWORD = '/reset-password';
const TOKEN = '/token';
const REQUEST = '/request';

// V1
export const ENDPOINTS = {
	OAUTH: {
		LOGIN: `${OAUTH}${TOKEN}`, // POST
		FORGOT_PASSWORD: `${PORTAL}${AUTH}${FORGOT_PASSWORD}`, // POST
		RESET_PASSWORD: `${PORTAL}${AUTH}${RESET_PASSWORD}`, // POST
		LOGOUT: `${PORTAL}${LOGOUT}`, // POST
	},
	PROFILE: {
		WHOAMI: `${PORTAL}${PROFILE}`, // GET
	},
	VERIFY: {
		REQUEST: `${PORTAL}${REQUEST}`, // POST
		DOWNLOAD: `${PORTAL}${REQUEST}${PDF}`, // POST
	},

	CONTACT: {
		STORE: `${PORTAL}${CONTACT}`, // POST
	},
};

export default ENDPOINTS;
