// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcGetState, AcSaveState } from '@utils';

export const AcGetAccessToken = () => {
	return AcGetState(KEYS.ACCESS_TOKEN);
};

export const AcSetAccessToken = data => {
	return AcSaveState(KEYS.ACCESS_TOKEN, data);
};
