// Imports => React
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-expandable',
  ANIMATED: 'ac-expandable--animated',
  EXPANDED: 'ac-expandable--expanded',
  OPEN: 'ac-expandable--open',
  TITLE: 'ac-expandable__title',
  CONTENT: {
    MAIN: 'ac-expandable__content',
    CHILDREN: 'ac-expandable__content__children',
  },
};

class AcExpandableController extends React.Component {
  constructor(props) {
    super(props);

    this.getBodyInlineStyles = this.getBodyInlineStyles.bind(this);
    this.calculateContentHeight = this.calculateContentHeight.bind(this);
    this.toggleExpandable = this.toggleExpandable.bind(this);

    this.content = React.createRef();

    this.state = {
      expanded: props.expanded,
      open: props.expanded,
      content: {
        height: 0,
        animated: false,
      },
    };
  }

  componentDidMount() {
    this.calculateContentHeight(false);
  }

  toggleExpandable(event, state = undefined, animated = true) {
    const { expanded } = this.state;

    this.setState(
      {
        expanded:
          typeof state !== 'undefined' && state !== null ? state : !expanded,
        open: false,
      },
      () => {
        this.calculateContentHeight(animated);
      }
    );
  }

  calculateContentHeight(animated = true) {
    let height = 0;

    if (this.content.current && this.content.current.childNodes) {
      const children = Array.prototype.slice.call(
        this.content.current.childNodes
      );
      children.forEach(node => (height += node.scrollHeight));
    }

    this.setState({
      content: {
        height,
        animated,
      },
      open: this.state.expanded,
    });
  }

  getStyleClassNames() {
    const { state, className } = this.props;
    const { content, expanded, open } = this.state;

    return clsx(
      _CLASSES.MAIN,
      content.animated && _CLASSES.ANIMATED,
      expanded && _CLASSES.EXPANDED,
      open && _CLASSES.OPEN,
      state && _CLASSES[state.toUpperCase()],
      className
    );
  }

  getTitleClassNames() {
    return clsx(_CLASSES.TITLE);
  }

  getContentClassNames() {
    return clsx(_CLASSES.CONTENT.MAIN);
  }

  getContentChildrenClassNames() {
    return clsx(_CLASSES.CONTENT.CHILDREN);
  }

  getBodyInlineStyles() {
    const {
      expanded,
      content: { height },
    } = this.state;

    return {
      height: expanded ? `${height / 10 || 0}rem` : '0rem',
    };
  }
}

AcExpandableController.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  expanded: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

AcExpandableController.defaultProps = {
  expanded: false,
};

export default AcExpandableController;
