// Imports => MOBX
import { observable, action } from 'mobx';

const _default = {
	modal: {
		visible: false,
		title: '',
		body: [],
		actions: [],
		closeable: true,
		callback: () => {},
	},
	navigation: {
		visible: false,
	},
	user_navigation: {
		visible: false,
	},
};

export class UiStore {
	constructor(store) {
		this.store = store;
	}

	@observable
	navigation = _default.navigation;

	@observable
	user_navigation = _default.user_navigation;

	@observable
	modal = _default.modal;

	@action
	set(target, value) {
		if (!target) return;
		if (!this[target]) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = {
			...this[target],
			...value,
		};
	}

	@action
	setState(target, property, value) {
		if (!target) return;
		if (!this[target]) return;
		if (!property) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target][property] = value;
	}

	@action
	reset(target) {
		if (!target) return;
		if (!this[target]) return;

		this[target] = _default[target];
	}
}

export default UiStore;
