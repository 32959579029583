export const ICONS = {
	ACCOUNT: 'account',
	ACCOUNT_OUTLINE_1: 'account-outline-1',
	ALERT_CIRCLE: 'alert-circle',
	ALERT_CIRCLE_OUTLINE: 'alert-circle-outline',
	ARROW_DOWN: 'arrow-down',
	ARROW_DOWN_THICK: 'arrow-down-thick',
	ARROW_LEFT: 'arrow-left',
	ARROW_LEFT_THICK: 'arrow-left-thick',
	ARROW_RIGHT: 'arrow-right',
	ARROW_RIGHT_THICK: 'arrow-right-thick',
	ARROW_UP: 'arrow-up',
	ARROW_UP_THICK: 'arrow-up-thick',
	CHECK: 'check',
	CHECK_ALL: 'check-all',
	CHECK_BOLD: 'check-bold',
	CHECKBOX_MARKED_CIRCLE_OUTLINE: 'checkbox-marked-circle-outline',
	CHEVRON_DOWN: 'chevron-down',
	CHEVRON_LEFT: 'chevron-left',
	CHEVRON_RIGHT: 'chevron-right',
	CHEVRON_UP: 'chevron-up',
	CLOSE: 'close',
	CLOSE_CIRCLE: 'close-circle',
	CLOSE_CIRCLE_OUTLINE: 'close-circle-outline',
	DOWNLOAD: 'download',
	DOWNLOAD_OUTLINE: 'download-outline',
	FILE_DOWNLOAD: 'file-download',
	FILE_DOWNLOAD_OUTLINE: 'file-download-outline',
	HELP: 'help',
	HELP_CIRCLE: 'help-circle',
	HELP_CIRCLE_OUTLINE: 'help-circle-outline',
	LOCK: 'lock',
	LOCK_OPEN: 'lock-open',
	LOCK_OPEN_OUTLINE: 'lock-open-outline',
	LOCK_OUTLINE: 'lock-outline',
	WINDOW_CLOSE: 'window-close',
};
