export const SYMBOLS = {
	EUR: {
		symbol: '\u20AC',
		code: 'EUR',
		symbol_native: '\u20AC',
		decimal_digits: 2,
		decimal_separator: ',',
		thousand_separator: '.',
		rounding: 0.0,
	},
	GBP: {
		symbol: '£',
		code: 'GBP',
		symbol_native: '£',
		decimal_digits: 2,
		decimal_separator: '.',
		thousand_separator: ',',
		rounding: 0.0,
	},
	USD: {
		symbol: 'US$',
		code: 'USD',
		symbol_native: 'US$',
		decimal_digits: 2,
		decimal_separator: '.',
		thousand_separator: ',',
		rounding: 0.0,
	},
};

export default SYMBOLS;
