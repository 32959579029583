export class AcParallax {
	constructor($target, $container, $scroller, translateY, scale, blur) {
		this.$target = $target;
		this.$container = $container;
		this.$scroller = $scroller ? $scroller : window;

		this.translateY = translateY || true;
		this.scale = scale || false;
		this.blur = blur || false;

		this.addEvent = this.addEvent.bind(this);
		this.removeEvent = this.removeEvent.bind(this);

		this.handleEvent = this.handleEvent.bind(this);
		this.setStyles = this.setStyles.bind(this);

		this.addEvent();
	}

	addEvent() {
		this.$scroller.addEventListener('scroll', this.handleEvent, false);
	}

	removeEvent() {
		this.$scroller.removeEventListener('scroll', this.handleEvent, false);
	}

	handleEvent() {
		let evRect = this.$scroller.getBoundingClientRect();
		let scrollTop = evRect.top; // + header height

		let rect = this.$container.getBoundingClientRect();

		let top = rect.top;
		let bottom = rect.top + rect.height;
		let Y = (scrollTop - top) * 0.3;
		let S = ((scrollTop - top) * 0.015) / 100 + 1;
		let B = Y / 10;

		let result = {
			translateY: 0,
			scale: 1,
			blur: 0,
		};

		if (scrollTop >= top && scrollTop <= bottom) {
			if (scrollTop <= 0 || scrollTop < 1) {
				Y = 0;
				S = 1;
				B = 0;
			} else {
				Y = Y < 1 ? 0 : Y;
				S = S < 1 ? 1 : S;
				B = B > 5 ? 5 : B < 0 ? 0 : B;
			}
		} else {
			Y = 0;
			S = 1;
			B = 0;
		}

		if (this.translateY) result.translateY = Y;
		if (this.scale) result.scale = S;
		if (this.blur) result.blur = B;

		this.setStyles(result);
	}

	setStyles(styles) {
		window.requestAnimationFrame(() => {
			if (this.$target) {
				this.$target.style.transform = `translateZ(0) translateY(${
					styles.translateY
				}px) scale(${styles.scale})`;

				this.$target.style.filter = `blur(${styles.blur}px)`;
			}
		});
	}
}

export default AcParallax;
