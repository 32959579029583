// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';

// Imports => Files
import Leaflet from '@assets/files/check-sociaal-leaflets-producten-en-diensten.pdf';
import DataInspectionLetter from '@assets/files/check-sociaal-brief-inzage-gegevens.doc';
import PrivacyStatement from '@assets/files/check-sociaal-privacy-statement-ggn-jan-2021.pdf';

export default [
	{
		uuid: AcUUID(),
		label: 'Handleiding',
		url: Leaflet,
	},
	{
		uuid: AcUUID(),
		label: 'Brief verzoek inzage gegevens',
		url: DataInspectionLetter,
	},
	{
		uuid: AcUUID(),
		label: 'Privacy Statement',
		url: PrivacyStatement,
	},
];
