// Imports => React
import React, { useEffect, useState, memo } from 'react';

import { RISKS, RISK_LEVEL, ADDITIONAL_ERROR_MESSAGE } from '@constants';
import { AcCapitalize, AcAddDotToSentence } from '@utils';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

// Component
const AcReportResult = memo(({ score = null, currentResult }) => {
  const [level, setLevel] = useState('unknown');
  const [errorMessage, setErrorMessage] = useState(null);
  const { result, message } = currentResult;

  useEffect(() => {
    setLevel(RISK_LEVEL[score]);
    setErrorMessage(
      AcAddDotToSentence(AcCapitalize(message)) +
        ADDITIONAL_ERROR_MESSAGE[result]
    );
  }, []);

  return (
    <AcRow>
      <AcColumn xs={12}>
        <div className={`ac-report-result ac-report-result--${level}-risk`}>
          <AcRichContent
            className={'ac-report-result__content'}
            content={RISKS[level]}
          />
          {result === '2' ? null : (
            <AcRichContent
              className={
                'ac-report-result__content ac-report-result__content--error'
              }
              content={errorMessage}
            />
          )}

          <AcRichContent
            className={'ac-report-result__disclaimer'}
            content={RISKS.disclaimer}
          />
        </div>
      </AcColumn>
    </AcRow>
  );
});

export default AcReportResult;
