// Imports => Utilities
import { AcGetAccessToken } from '@utils';

// Get ENV variables
const ENV = process.env;
const _api_ = ENV.API_URL;
const _site_ = ENV.SITE;
const _mode_ = ENV.MODE;

const _auto_logout = ENV.AUTO_LOGOUT;
const _auto_logout_time = ENV.AUTO_LOGOUT_TIME;

const _register_uri_ = ENV.REGISTER_URL;

export default {
	mode: _mode_,
	site: _site_,
	register_uri: _register_uri_
		? _register_uri_
		: _site_ === 'check_sociaal'
		? 'https://ggn.nl/checksociaal/aanmelden'
		: 'https://ggn.nl/verhuursignaal/aanmelden',
	autologout: {
		active: _auto_logout || false,
		time: _auto_logout_time || 0,
	},
	rollbar: {
		accessToken: ENV.ROLLBAR_KEY,
		captureUncaught: true,
		captureUnhandledRejections: true,
		verbose: false,
		payload: {
			environment: ENV.ROLLBAR_ENVIRONMENT,
		},
	},
	api: {
		baseURL: `${_api_}`,
		timeout: 1000 * 60,
		maxContentLength: 10000,
		responseType: 'json',
		responseEncoding: 'utf8',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'x-origin': _site_,
		},
		transformRequest: [
			(data, headers) => {
				const token = AcGetAccessToken();
				if (token) headers['authorization'] = `Bearer ${token}`;
				return JSON.stringify(data);
			},
		],
	},
	download: {
		baseURL: `${_api_}`,
		timeout: 1000 * 60,
		maxContentLength: 10000,
		responseType: 'blob',
		responseEncoding: 'utf8',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/pdf',
			'x-origin': _site_,
		},
		transformRequest: [
			(data, headers) => {
				const token = AcGetAccessToken();
				if (token) headers['authorization'] = `Bearer ${token}`;
				return JSON.stringify(data);
			},
		],
	},
};
