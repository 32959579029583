// Imports => React
import React from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Fade } from 'react-reveal';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, ROUTES, SUB_NAVIGATION_ITEMS, THEMES, SIZES } from '@constants';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
	MAIN: 'ac-sub-navigation',
	LIST: {
		MAIN: 'ac-sub-navigation__list',
		ITEM: 'ac-sub-navigation__item',
	},
	LINK: {
		MAIN: 'ac-sub-navigation__link',
		STATIC: 'ac-sub-navigation__link--static',
		ACTIVE: 'ac-sub-navigation__link--active',
	},
	INDICATOR: {
		MAIN: 'ac-sub-navigation__indicator',
		STATIC: 'ac-sub-navigation__indicator--static',
		LEFT: 'ac-sub-navigation__indicator--move-left',
		RIGHT: 'ac-sub-navigation__indicator--move-right',
	},
};

const AcSubNavigation = ({ store, routing, indicate = true }) => {
	const { current_profile } = store.profile;
	const { is_authorized, current_role } = store.auth;

	const routes = SUB_NAVIGATION_ITEMS.filter(item =>
		item.roles ? item.roles.indexOf(current_role) > -1 : true
	);

	const $indicator = React.createRef();
	const $navigation = React.createRef();

	const isActive = (match, location) => {
		return match !== null ? match.url === location.pathname : false;
	};

	const handleClickEvent = (event, route) => {
		const { push } = routing.history;

		if (push && route && route.path) push(route.path);
	};

	const handleLogin = async event => {
		if (event && event.preventDefault) event.preventDefault();
		const { replace } = routing.history;

		if (replace) replace(ROUTES.HOME.path);
	};

	const handleLogout = async event => {
		if (event && event.preventDefault) event.preventDefault();
		const { replace } = routing.history;

		await store.auth.logout();
		if (replace) replace(ROUTES.HOME.path);
	};

	const getNavLinkClassNames = (route, _static) => {
		const { location } = routing.history;

		let active = false;
		if (route && location) {
			active = location.pathname === route.path;
		}

		return clsx(
			_CLASSES.LINK.MAIN,
			active && _CLASSES.LINK.ACTIVE,
			_static && _CLASSES.LINK.STATIC
		);
	};

	const getNavListItemClassNames = () => {
		return clsx(_CLASSES.LIST.ITEM);
	};

	const getNavListClassNames = () => {
		return clsx(_CLASSES.LIST.MAIN);
	};

	const getIndicatorClassNames = () => {
		return clsx(_CLASSES.INDICATOR.MAIN);
	};

	const getStyleClassNames = () => {
		return clsx(_CLASSES.MAIN);
	};

	return (
		<nav className={getStyleClassNames()} ref={$navigation}>
			<ul className={getNavListClassNames()}>
				<>
					{is_authorized && current_profile && current_profile.name && (
						<li className={getNavListItemClassNames()}>
							<div className={getNavLinkClassNames(null, 'static')}>
								<Fade>
									<>
										<AcIcon icon={ICONS.ACCOUNT_OUTLINE_1} />
										{current_profile.name}
										{current_profile.company && (
											<span className={'ac-sub-navigation__company'}>
												{` (${current_profile.company.name})`}
											</span>
										)}
									</>
								</Fade>
							</div>
						</li>
					)}
					{!store.auth.is_authorized && (
						<li className={getNavListItemClassNames()}>
							<div className={getNavLinkClassNames()} onClick={handleLogin}>
								<Fade>
									<>
										<AcIcon icon={ICONS.LOCK_OUTLINE} />
										Inloggen
										<AcRipple size={'small'} simple />
									</>
								</Fade>
							</div>
						</li>
					)}

					{store.auth.is_authorized && (
						<li className={getNavListItemClassNames()}>
							<div className={getNavLinkClassNames()} onClick={handleLogout}>
								<Fade>
									<>
										<AcIcon icon={ICONS.LOCK_OPEN_OUTLINE} />
										Uitloggen
										<AcRipple size={'small'} simple />
									</>
								</Fade>
							</div>
						</li>
					)}

					{routes &&
						routes.map(route => (
							<li
								key={route.id}
								ref={node => (route.$ref = node)}
								className={getNavListItemClassNames()}
							>
								<NavLink
									to={route.path}
									onClick={event => {
										handleClickEvent(event, route);
									}}
									className={getNavLinkClassNames(route)}
									activeClassName={getNavLinkClassNames(route)}
									isActive={isActive}
									title={route.title}
									exact
									strict
								>
									{route.label}
									<AcRipple theme={THEMES.LIGHT} size={SIZES.SMALL} simple />
								</NavLink>
							</li>
						))}
				</>
			</ul>

			{indicate && (
				<span ref={$indicator} className={getIndicatorClassNames()} />
			)}
		</nav>
	);
};

export default inject('routing', 'store')(observer(AcSubNavigation));
