import 'react-app-polyfill/ie11';
import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import { register, unregister } from './registerServiceWorker';

import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';

import '@styles';
import '@utils/ac-array-helpers';

import config from '@config';
import createStore from '@stores';

import App from '@src/App';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const store = createStore(config);

const storesCollection = {
	routing: routingStore,
	store,
};

const history = syncHistoryWithStore(browserHistory, routingStore);

// kick off the smoothscroll polyfill!
smoothscroll.polyfill();

ReactDOM.render(
	<Provider {...storesCollection}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	document.getElementById('root')
);

if (process.env.NODE_ENV === 'production') {
	register();
} else {
	unregister();
}
