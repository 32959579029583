// Imports => React
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Constants
import { SIZES, THEMES } from '@constants';

// Imports => Utilities
import { AcIndicator } from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
	MAIN: 'ac-navigation',
	VISIBLE: 'ac-navigation--visible',
	NAVLIST: {
		MAIN: 'ac-navigation__list',
	},
	NAVLISTITEM: {
		MAIN: 'ac-navigation__item',
	},
	NAVLINK: {
		MAIN: 'ac-navigation__link',
		ACTIVE: 'ac-navigation__link--active',
	},
	INDICATOR: {
		MAIN: 'ac-navigation__indicator',
		STATIC: 'ac-navigation__indicator--static',
		LEFT: 'ac-navigation__indicator--move-left',
		RIGHT: 'ac-navigation__indicator--move-right',
	},
};

const AcNavigation = ({
	store,
	history,
	indicate = true,
	routes,
	className,
}) => {
	let indicator = null;
	let $indicator = React.createRef();
	let $navigation = React.createRef();

	let unsubscribeFromStore = null;

	useEffect(() => {
		unsubscribeFromStore = history.subscribe((location, action) =>
			subscribe(location, action)
		);

		if (indicate) {
			if (indicator) {
				indicator.update(routes);
			} else if (!indicator) {
				indicator = new AcIndicator(
					$navigation,
					$indicator,
					routes,
					_CLASSES.INDICATOR
				);
			}
			init();
		}

		return () => {
			if (unsubscribeFromStore) unsubscribeFromStore();
			if (indicator) indicator.unload();
		};
	}, [$indicator, $navigation, routes]);

	const subscribe = location => {
		let found = false;
		let n = 0;
		let len = routes.length;

		for (n; n < len; n++) {
			const route = routes[n];

			if (route.path === location.pathname) {
				found = route;
				handleClickEvent({}, route);
				break;
			}
		}

		if (!found && indicator) {
			indicator.reset();
		} else if (found && indicator) {
			indicator.recalculate({}, found);
		}
	};

	const init = () => {
		const { location } = history;
		let n = 0;
		let len = routes.length;

		for (n; n < len; n++) {
			const route = routes[n];

			if (route.path === location.pathname) {
				if (route.$ref) {
					route.$ref.click();
				}

				break;
			}
		}
	};

	const isActive = (match, location) => {
		return match !== null ? match.url === location.pathname : false;
	};

	const handleClickEvent = (event, route) => {
		if (route.callback) route.callback(event, route);
	};

	const getNavLinkClassNames = (route, active) => {
		return clsx(
			_CLASSES.NAVLINK.MAIN,
			active && _CLASSES.NAVLINK.ACTIVE,
			route.className && `${_CLASSES.NAVLINK.MAIN}--${route.className}`
		);
	};

	const getNavListItemClassNames = () => {
		return clsx(_CLASSES.NAVLISTITEM.MAIN);
	};

	const getNavListClassNames = () => {
		return clsx(_CLASSES.NAVLIST.MAIN);
	};

	const getIndicatorClassNames = () => {
		return clsx(_CLASSES.INDICATOR.MAIN);
	};

	const getStyleClassNames = () => {
		const { navigation } = store.ui;

		return clsx(
			_CLASSES.MAIN,
			navigation && navigation.visible && _CLASSES.VISIBLE,
			className && className
		);
	};

	return (
		<nav ref={$navigation} className={getStyleClassNames()}>
			<ul className={getNavListClassNames()}>
				{routes &&
					routes.map(route => (
						<li
							key={route.id}
							ref={node => (route.$ref = node)}
							className={getNavListItemClassNames()}
						>
							<NavLink
								to={route.path}
								onClick={event => {
									handleClickEvent(event, route);
								}}
								className={getNavLinkClassNames(route)}
								activeClassName={getNavLinkClassNames(route, 'active')}
								isActive={isActive}
								title={route.title}
								exact
								strict
							>
								{route.label}
								<AcRipple theme={THEMES.LIGHT} size={SIZES.SMALL} simple />
							</NavLink>
						</li>
					))}
			</ul>

			{indicate && (
				<span ref={$indicator} className={getIndicatorClassNames()} />
			)}
		</nav>
	);
};

export default withRouter(AcNavigation);
