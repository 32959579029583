// Imports => React
import React, { useCallback, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { VISUALS } from '@constants';

const _CLASSES = {
	MAIN: 'ac-logo',
	LIGHT: 'ac-logo--light',
	DARK: 'ac-logo--dark',
	ALPHA: 'ac-logo--alpha',
	CONTAINER: {
		MAIN: 'ac-logo__container',
	},
	VISUAL: {
		MAIN: 'ac-logo__visual',
	},
};

// Component
const AcLogo = ({ Visual = VISUALS.LOGO_GGN, callback }) => {
	const getContainerClassNames = useCallback(() => {
		return clsx(_CLASSES.CONTAINER.MAIN);
	}, []);

	const getStyleClassNames = useCallback(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const renderLogo = useMemo(() => {
		let Logo = VISUALS.LOGO_GGN;

		return <Logo />;
	});

	return (
		<div className={getStyleClassNames()} onClick={callback}>
			<div className={getContainerClassNames()}>{renderLogo}</div>
		</div>
	);
};

export default memo(AcLogo);
