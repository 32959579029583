// Imports => React
import React, { useCallback, useMemo, memo } from 'react';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-hamburger',
	OPEN: 'ac-hamburger--open',
	LAYER: {
		MAIN: 'ac-hamburger__layer',
		PREFIX: 'ac-hamburger__layer--',
	},
	LABEL: 'ac-hamburger__label',
};

// Component
const AcHamburger = ({ open, callback, className }) => {
	const handleCallback = useCallback(() => {
		if (callback) callback();
	}, [callback]);

	const getLabelClassNames = useMemo(() => {
		return clsx(_CLASSES.LABEL);
	}, []);

	const getLayerClassNames = useCallback(n => {
		return clsx(_CLASSES.LAYER.MAIN, `${_CLASSES.LAYER.PREFIX}${n}`);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, open && _CLASSES.OPEN, className);
	}, [open, className]);

	return (
		<div className={getStyleClassNames} onClick={handleCallback}>
			<span className={getLayerClassNames(1)} />
			<span className={getLayerClassNames(2)} />
			<span className={getLayerClassNames(3)} />
			<span className={getLabelClassNames}>Menu</span>
		</div>
	);
};

export default memo(AcHamburger);
