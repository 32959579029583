// Imports => Utilities
import { AcClasses, AcGetClosestElement } from '@utils';

Math.easeInOutQuad = (t, b, c, d) => {
	t /= d / 2;
	if (t < 1) return (c / 2) * t * t + b;
	t--;
	return (-c / 2) * (t * (t - 2) - 1) + b;
};

export class AcSlotDropdown {
	constructor($dropdown, $target, $container, slotClass, activeClass) {
		this.Classes = new AcClasses();

		this.slotClass = slotClass;
		this.activeClass = activeClass;

		this.$dropdown = $dropdown;
		this.$target = $target;
		this.$container = $container;
		this.$scroller = document.getElementById('ac-scroller');

		this.init = this.init.bind(this);
		this.recalculate = this.recalculate.bind(this);
		this.inViewport = this.inViewport.bind(this);
		this.hide = this.hide.bind(this);
		this.setStyles = this.setStyles.bind(this);
		this.movePointer = this.movePointer.bind(this);

		this.init();
	}

	init() {}

	recalculate(event, $container) {
		if (!event || !$container) return;
		this.$target = event.target;
		this.$container = $container;

		this.Classes.removeClass(this.$dropdown.current, this.activeClass);

		const rect = this.$dropdown.current;
		const container = this.$container.current;
		const button = this.$target.getBoundingClientRect();
		const parent = AcGetClosestElement(this.$target, `.${this.slotClass}`);
		const scrollTop = this.$scroller.scrollTop;

		const position = {
			x:
				button.left +
				button.width / 2 -
				rect.clientWidth / 2 -
				container.offsetLeft,
			y: button.top + button.height - parent.clientHeight + scrollTop,
		};

		const check = this.inViewport(rect, container, position);

		if (check.right) {
			position.x = position.x - rect.clientWidth / 2 + button.width;
		}

		this.movePointer(check.right ? 'right' : check.left ? 'left' : 'center');
		this.setStyles(position);
	}

	inViewport(_element, _container, position) {
		const width = _element.clientWidth / 2;

		const _window = {
			offset: {
				left: _container.offsetLeft,
				right: _container.clientWidth,
				top: _container.offsetTop,
			},
			left_side: _container.offsetLeft,
			right_side: _container.clientWidth - _container.offsetLeft,
		};

		const _dropdown = {
			width,
			position,
			left_side: position.x - width,
			right_side: position.x + width + _window.offset.left,
		};

		// Check if it's out of the viewport on each side
		const result = {
			left: _dropdown.left_side < _container.left_side,
			right: _dropdown.right_side > _window.right_side,
		};

		return result;
	}

	hide(event) {
		this.Classes.removeClass(this.$dropdown.current, this.activeClass);
	}

	setStyles(position) {
		if (!position) return;

		window.requestAnimationFrame(async () => {
			let transforms = ['translateZ(0)'];
			transforms = [...transforms, `translateX(${position.x}px)`];
			transforms = [...transforms, `translateY(${position.y}px)`];

			if (this.$dropdown.current)
				this.$dropdown.current.style.transform = transforms.join(' ');
			this.Classes.addClass(this.$dropdown.current, this.activeClass);
		});
	}

	movePointer(direction) {
		window.requestAnimationFrame(async () => {
			const pointer = document.getElementById('ac-slot-dropdown-pointer');
			const list = document.getElementById('ac-slot-dropdown-list');
			const position = {
				x:
					direction === 'right'
						? this.$dropdown.current.clientWidth / 2 - 110
						: direction === 'left'
						? this.$dropdown.current.clientWidth / 2 - 110 * -1
						: 0,
				y: 0,
			};
			let transforms = ['rotate(-45deg) translateZ(0)'];
			transforms = [...transforms, `translateX(${position.x}px)`];
			transforms = [...transforms, `translateY(${position.x}px)`];

			if (pointer) pointer.style.transform = transforms.join(' ');

			if (list)
				list.style.transformOrigin =
					direction === 'right'
						? '100% 0%'
						: direction === 'left'
						? '0% 0%'
						: '50% 0%';
		});
	}
}

export default AcSlotDropdown;
