// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';

// Imports => Files
import PrivacyStatement from '@assets/files/check-sociaal-privacy-statement-ggn-jan-2021.pdf';

export default [
	{
		uuid: AcUUID(),
		title: 'Wat is Check Sociaal?',
		content:
			'<p>Check Sociaal is een via GGN aangeboden effectief preventiemiddel voor woningcorporaties. Check Sociaal geeft duidelijkheid over de betaalwaardigheid en de identiteit van de nieuwe huurder. Zo bent u in staat om (potentiële) ‘probleemhuurders’ tijdig een helpende hand te bieden en huurachterstanden en ontruimingen te voorkomen.</p>',
	},
	{
		uuid: AcUUID(),
		title: 'Hoe werkt Check Sociaal?',
		content:
			'<p>Check Sociaal is een online check die via de website van GGN, of direct via <a href="https://www.checksociaal.nl/">www.checksociaal.nl</a>, te benaderen is. Door het ingeven van naam, adres, woonplaats en geboortedatum van de kandidaat-huurder krijgt u direct online een betaalwaardigheidsadvies op persoon.</p>',
	},
	{
		uuid: AcUUID(),
		title: 'Uit welke modules bestaat Check Sociaal?',
		content:
			'<p>Check Sociaal bestaat uit twee modules, namelijk betaalgedrag en identiteit. Bij de module betaalgedrag wordt de betaalwaardigheid gecontroleerd aan de hand van positieve en negatieve betalingservaringen en registraties. Bij de module identiteit wordt de geldigheid en fraude van nationale reisdocumenten en rijbewijzen gecontroleerd aan de hand van het documentnummer.</p>',
	},
	{
		uuid: AcUUID(),
		title: 'Wanneer kan ik Check Sociaal inzetten?',
		content:
			'<p>Wanneer u een huurcontract afsluit met nieuwe huurders, weet u nog niet met wie u van doen heeft. Moet u rekening houden met risico’s als huurachterstanden, woonfraude of ander wangedrag? Of hoeft u zich nergens zorgen om te maken? </p><p>Achterstanden bij de huurbetaling zijn een belangrijk signaal voor het ontstaan van problematische schulden. Woningcorporaties en sociale partners hebben een groot belang bij het terugdringen van huurschulden en het voorkomen van ontruimingen. Vroegtijdige signalering van (betalings)problemen is daarom ook een sleutelbegrip in de aanpak van corporaties.</p><p>Met Check Sociaal heeft u als woningcorporatie de mogelijkheid om preventief te werken. Door deze aanpak worden huurachterstanden en ontruimingen effectief voorkomen.</p>',
	},
	{
		uuid: AcUUID(),
		title: 'Maakt GGN gebruik van eigen data in Check Sociaal?',
		content:
			'<p>Nee, GGN maakt in Check Sociaal enkel en alleen gebruik van externe data. Hiervoor hebben wij een samenwerking met Experian. GGN levert zelf geen data (voor-of achteraf) aan noch bewerkt of verrijkt GGN de data. Experian verzamelt informatie van consumenten en stelt deze onder strikte voorwaarden beschikbaar. Experian stelt hoge eisen aan de kwaliteit van informatie. Het is immers van groot belang dat u beschikt over de meest accurate, betrouwbare en actuele informatie.</p>',
	},
	{
		uuid: AcUUID(),
		title: 'Hoe zijn de privacyregels gewaarborgd?',
		content: `<p>Wij, GGN en Experian, besteden de grootst mogelijke zorg aan onze bronnen en databestanden. Experian is aangesloten bij de <em>Nederlandse Vereniging van Handelsinformatiebureaus (hierna: NVH)</em> en zowel GGN als Experian werken strikt conform de <em>Algemene Verordening Gegevensbescherming (hierna: AVG)</em>. Bovendien hanteren wij de hoogst mogelijke internatonale voorschriften ten aanzien van de veiligheid van onze systemen. GGN brengt alleen de verbinding tot stand en verwerkt in deze geen gegevens.</p><p><a href="${PrivacyStatement}" title="GGN Privacy Statement" target="_blank">Bekijk hier de privacystatement van GGN</a>.</p>`,
	},
	{
		uuid: AcUUID(),
		title:
			'Hoe krijgt mijn potentiële huurkandidaat inzage in zijn/haar gegevens?',
		content:
			'<p>GGN en Experian dienen erop toe te zien dat de verstrekte gegevens conform de AVG worden gebruikt. Om die reden is Experian verantwoordelijke in de zin van de AVG en uit dien hoofde in deze uitsluitend bevoegd verzoeken om inzage in eigen gegevens te honoreren.</p><p>Op grond van de AVG regelgeving krijgt enkel de huurkandidaat inzicht in zijn/ haar eigen gegevens en uitsluitend onder bepaalde voorwaarden. De potentiële huurkandidaat kan een dergelijk verzoek schriftelijk richten tot:</p><p><strong>Experian</strong><br>Afdeling Customer Support<br>Postbus 16604<br>2500 BP, Den Haag </p><p>Bij dit verzoek dient uw huurkandidaat een kopie van een legitimatiebewijs bij te sluiten om te kunnen garanderen dat de gegevens naar de juiste persoon worden gezonden. Uw huurkandidaat krijgt binnen vier weken een overzicht van zijn/haar gegevens.</p><p>Voor meer informatie over een registratie verwijzen wij naar Experian:<br><a href="https://www.experian.nl/over-experian/over-uw-registratie" target="_blank" rel="noopener nofollow noreferrer">www.experian.nl/over-experian/over-uw-registratie</a>.</p>',
	},
	{
		uuid: AcUUID(),
		title: 'Wie mag er toegang hebben tot Check Sociaal?',
		content:
			'<p>Dat bepaal je zelf. Maar omdat het om privacygevoelige gegevens gaat, is het belangrijk dat de gebruiker dit nodig heeft voor het uitvoeren van zijn/haar werk. Je kunt elke gebruiker aanmelden met eigen mailadres. Daar kiest deze zelf een wachtwoord bij. Dit is kosteloos. Op deze manier weten wij wie er mag aanvragen en kunnen dat daardoor ook verantwoorden als dit gecontroleerd wordt.</p>',
	},
	{
		uuid: AcUUID(),
		title:
			'Ik kan niet inloggen of krijg een foutmelding bij een aanvraag. Waar kan ik dat melden?',
		content:
			'<p>Dat kun je doen via <a href="mailto:sales@ggn.nl">sales@ggn.nl</a> of bel ons op <a href="tel:0883316678">088-3316678</a></p><p><strong>Let op:</strong> Als u langer dan 3 maanden geen aanvraag heeft gedaan, dan wordt uw account uit veiligheidsoverwegingen geblokkeerd. U kunt dan wel inloggen maar geen checks uitvoeren. Dit is niet te voorkomen, maar wel snel te verhelpen als u het ons laat weten.</p>',
	},
];
