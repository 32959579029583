// Imports => Utilities
import { AcCapitalize } from '@utils';

export const AcFormatErrorMessage = error => {
  let msg = false;
  let code = error && error.status ? error.status : false;

  if (!code || code === 400) {
    return `Inloggen mislukt. Controleer je gebruikersnaam/wachtwoord combinatie en eventueel de wachtwoord reset. Mocht het probleem voortduren, neem contact op met sales@ggn.nl `;
  }

  if (!code || code === 500) {
    return `Er is een onbekende fout opgetreden. Probeer het opnieuw of neem contact op met sales@ggn.nl`;
  }

  if (error.response && error.response.data && error.response.data.errors) {
    msg = [];

    for (let key in error.response.data.errors) {
      if (error.response.data.errors.hasOwnProperty(key)) {
        if (error.response.data.errors[key][0] !== 'p')
          msg.push(AcCapitalize(error.response.data.errors[key][0]));
      }
    }

    msg = msg.join(', ');
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    msg = error.response.data.message;
  }

  return msg;
};

export const AcFormatErrorCode = error => {
  return error && error.response && error.response.status
    ? error.response.status
    : 'Netwerk Error';
};

export default {
  AcFormatErrorMessage,
  AcFormatErrorCode,
};
