// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Utilities
import { AcUUID, AcIsSet } from '@utils';

export class ToastersStore {
	constructor(store) {
		this.store = store;
	}

	@observable
	collection = [];

	@computed
	get queue() {
		return this.collection.length > 0 ? this.collection : [];
	}

	@action
	get() {
		return this.collection.length > 0 ? this.collection : [];
	}

	@action
	add = options => {
		/*
			options: {
				variant: <string>,
				title: <string>,
				description: <string>,
				delay: <int>,
			}
		*/

		const time = new Date().getTime();
		const delay = options.delay ? options.delay : 1000 * 5;
		const expires = time + delay + 1500;

		const toast = {
			...options,
			id: AcUUID(),
			delay,
			time,
			expires,
		};

		this.collection.push(toast);
	};

	@action
	remove = id => {
		const collection = this.collection;
		let new_collection = null;

		if (!AcIsSet(id)) {
			new_collection = collection.shift();
		} else {
			new_collection = collection.filter(item => item.id !== id);
		}

		this.collection = new_collection;
	};
}

export default ToastersStore;
