// Imports => React
import React from 'react';
import clsx from 'clsx';
import Rollbar from 'rollbar';

// Imports => Constants
import { ENVIRONMENTS } from '@constants';

// Imports => Config
import config from '@config';

const _CLASSES = {
	MAIN: 'ac-error-boundary',
};

class AcErrorBoundaryController extends React.PureComponent {
	constructor(props) {
		super(props);

		this.excluded = [ENVIRONMENTS.DEV, ENVIRONMENTS.TEST, ENVIRONMENTS.LOCAL];
		this.rollbar = new Rollbar(config.rollbar);
	}

	componentDidCatch(error, info) {
		this.throwError(error);
	}

	logInfo = info => {
		const {
			payload: { environment },
		} = config.rollbar;
		// Do not log info messages during development or on the TEST environment
		if (this.excluded.indexOf(environment) !== -1) {
			return;
		}
		// Example log event using the rollbar object.
		this.rollbar.info(info || 'example info message from ac-error-boundary');
	};

	throwError = error => {
		const {
			payload: { environment },
		} = config.rollbar;
		// Do not log error messages during development or on the TEST environment
		if (this.excluded.indexOf(environment) !== -1) {
			return;
		}
		// Example error, which will be reported to rollbar.
		this.rollbar.error(error || 'example error from ac-error-boundary');
	};

	getStyleClassNames() {
		return clsx(_CLASSES.MAIN);
	}
}

export default AcErrorBoundaryController;
