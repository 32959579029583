export const KEYS = {
	ACCESS_TOKEN: 'access_token',
	ACCOUNT: 'account',
	ACTIVE: 'active',
	ADD: 'add',
	ADMIN: 'admin',
	ANNOUNCEMENTS: 'announcements',
	ASCENDING: 'ascending',
	CART: 'cart',
	CASH: 'cash',
	CHANGE: 'change',
	CHECK_SOCIAAL: 'check_sociaal',
	COINBAGS: 'coin-bags',
	COINROLLS: 'coin-rolls',
	COINS: 'coins',
	COMPANIES: 'companies',
	COMPANY: 'company',
	COMPANY_ADMIN: 'company_admin',
	COMPANY_ID: 'company_id',
	CONTENTS: 'contents',
	CREATED_AT: 'created_at',
	CREDIT_CHECK: 'credit_check',
	CURRENT_MONTH: 'current-month',
	CURRENT_QUARTER: 'current-quarter',
	CURRENT_WEEK: 'current-week',
	DELETE: 'delete',
	DELIVER_SEALBAGS: 'delivery',
	DELIVERY: 'delivery',
	DELIVERY_DATE: 'delivery_date',
	DELIVERY_SLOTS: 'delivery_slots',
	DESCENDING: 'descending',
	DRIVERS_LICENSE: 'RB',
	EMAIL: 'email',
	EMPHASIZED: 'emphasized',
	EXPIRES_AT: 'expires_at',
	EXPIRES_IN: 'expires_in',
	FEMALE: 'female',
	FIELDS: 'fields',
	FILTERS: 'filters',
	FOILBAGS: 'foil-bags',
	FORGOT_PASSWORD: 'forgot-password',
	GROUP: 'group',
	GROUPS: 'groups',
	HOLIDAYS: 'holidays',
	IDENTITY_CARD: 'VN',
	IDENTITY_CHECK: 'identity_check',
	INACTIVE: 'inactive',
	INTERAL_REFERENCE: 'internal_reference',
	LOCALE: 'locale',
	LOCATION: 'location',
	LOCATION_ID: 'location_id',
	LOCATIONS: 'locations',
	LOGIN: 'login',
	MALE: 'male',
	MANAGER: 'manager',
	META: 'meta',
	MODAL: 'modal',
	NAME: 'name',
	NAVIGATION: 'navigation',
	NEW_PASSWORD: 'new_password',
	NEW_PASSWORD_CONFIRMATION: 'new_password_confirmation',
	NEXT: 'next',
	NOTES: 'notes',
	OPTIONS: 'options',
	ORDER: 'order',
	ORDER_DATE: 'created_at',
	ORDER_NO: 'id',
	ORDER_ROWS: 'order_rows',
	ORDERS: 'orders',
	PAGINATION: 'pagination',
	PASSPORT: 'PP',
	PASSWORD: 'password',
	PENDING: 'pending',
	PREVIOUS: 'previous',
	PREVIOUS_MONTH: 'previous-month',
	PROFILE: 'profile',
	QUERY: 'query',
	REFRESH_TOKEN: 'refresh_token',
	REPORT: 'report',
	REQUEST: 'request',
	RESET_PASSWORD: 'reset-password',
	ROLE: 'role',
	SCROLLER: 'ac-scroller',
	SESSION: 'session',
	SUBTLE: 'subtle',
	SUPPORT_EMAIL_ADDRESS: 'support@rccs.nl',
	TODAY: 'today',
	TYPE: 'type',
	UNIT: 'unit',
	UPDATE: 'update',
	USER: 'user',
	USER_NAVIGATION: 'user_navigation',
	USER_ROLE: 'user_role',
	USERNAME: 'username',
	USERS: 'users',
	V1: 'V1',
	V2: 'V2',
	VERHUUR_SIGNAAL: 'verhuur_signaal',
	VISIBLE: 'visible',
};
