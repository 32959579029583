// Imports => Constants
import { ENDPOINTS } from '@constants';

export class VerifyAPI {
  constructor(Instance) {
    this.Client = Instance.Client;
    this.DownloadClient = Instance.DownloadClient;
    this.Store = Instance.Store;
  }

  request = _data => {
    return this.Client.post(ENDPOINTS.VERIFY.REQUEST, _data).then(
      response => response.data
    );
  };

  download = _data => {
    return this.DownloadClient.post(ENDPOINTS.VERIFY.DOWNLOAD, {
      ..._data,
    }).then(response => response.data);
  };
}

export default VerifyAPI;
