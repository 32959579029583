// Imports => MOBX
import { observable, computed, action } from 'mobx';
import moment from 'moment';

// Imports => Config
import config from '@config';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcFormatErrorCode,
  AcFormatErrorMessage,
  AcIsUndefined,
  AcIsSet,
  AcDownloadFile,
} from '@utils';

const _default = {
  session: null,
  report: null,
};

let app = {};

export class VerifyStore {
  constructor(store) {
    AcAutoLoad(this, KEYS.SESSION);
    AcAutoLoad(this, KEYS.REPORT);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  session = _default.session;

  @observable
  report = _default.report;

  @computed
  get current_session() {
    if (!AcIsSet(this.session)) return null;

    const encoded = this.session;
    const decoded = JSON.parse(window.atob(encoded));
    return decoded;
  }

  @computed
  get current_report() {
    if (!AcIsSet(this.report)) return null;

    const encoded = this.report;
    const decoded = JSON.parse(window.atob(encoded));
    return decoded;
  }

  @observable
  loading = {
    status: false,
  };

  @computed
  get is_loading() {
    return this.loading.status;
  }

  @action
  setLoading = state => {
    this.loading = {
      status: state || false,
    };
  };

  @action
  request = data => {
    if (!data) return;

    this.setLoading(true);

    return app.store.api.verify
      .request(data)
      .then(response => {
        const _session = window.btoa(JSON.stringify(data));
        const _report = window.btoa(JSON.stringify(response));

        this.set(KEYS.SESSION, _session, true);
        this.set(KEYS.REPORT, _report, true);

        this.setLoading(false);
        return response;
      })
      .catch(error => {
        app.store.toasters.add({
          variant: 'error',
          delay: 5000,
          title: 'Failed to retreive the data',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        this.setLoading(false);
        throw error;
      });
  };

  @action
  download = () => {
    if (!this.current_session) return;

    this.setLoading(true);

    const session = this.current_session;

    return app.store.api.verify
      .download(session)
      .then(response => {
        const now = moment().format('DDMMYYYY');

        AcDownloadFile(
          response,
          `rapport_${config.site}_${now}.pdf`,
          'application/pdf'
        );

        this.setLoading(false);
        return response;
      })
      .catch(error => {
        app.store.toasters.add({
          variant: 'error',
          delay: 5000,
          title: 'Downloaden van rapport is niet gelukt',
          description: AcFormatErrorMessage(error),
          code: AcFormatErrorCode(error),
        });

        this.setLoading(false);
        throw error;
      });
  };

  @action
  set = (key, value, store) => {
    if (AcIsUndefined(key)) return;
    if (AcIsUndefined(this[key])) return;

    this[key] = value;

    if (store) AcSaveState(key, value);
  };

  @action
  reset = key => {
    if (AcIsUndefined(key)) return;
    if (AcIsUndefined(this[key])) return;

    this[key] = _default[key];
    AcRemoveState(key);
  };
}

export default VerifyStore;
