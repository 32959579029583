// Imports => React
import React from 'react';
import clsx from 'clsx';

// Imports => Constants
import { VISUALS } from '@constants';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';

const _CLASSES = {
	MAIN: 'ac-footer',
	SECTION: {
		MAIN: 'ac-footer__section',
		META: 'ac-footer__section--meta',
	},
	CONTAINER: {
		MAIN: 'ac-footer__container',
	},
	EDGE: {
		MAIN: 'ac-footer__edge',
		FIRST: 'ac-footer__edge--first',
		SECOND: 'ac-footer__edge--second',
		THIRD: 'ac-footer__edge--third',
	},
};

const AcFooter = ({ children }) => {
	const getEdgeClassNames = n => {
		return clsx([
			_CLASSES.EDGE.MAIN,
			n && _CLASSES.EDGE[n.toUpperCase()],
		]);
	};

	const getContainerClassNames = () => {
		return clsx([_CLASSES.CONTAINER.MAIN]);
	};

	const getSectionClassNames = meta => {
		return clsx([_CLASSES.SECTION.MAIN, meta && _CLASSES.SECTION.META]);
	};

	const getStyleClassNames = () => {
		return clsx([_CLASSES.MAIN]);
	};

	const GGN = VISUALS.LOGO_GGN_ALT;
	const Experian = VISUALS.POWERED_BY_EXPERIAN;

	return (
		<footer className={getStyleClassNames()} id={getStyleClassNames()}>
			<div className={getEdgeClassNames('first')} />
			<div className={getEdgeClassNames('second')} />

			<div className={getSectionClassNames()}>
				<div className={getEdgeClassNames('third')} />
				<AcContainer className={getContainerClassNames()}>
					<AcRow>
						<AcColumn>
							<a
								href={'https://www.ggn.nl/'}
								target={'_blank'}
								rel={'noopener nofollow noreferrer'}
								title={'GGN Mastering Credit'}
							>
								<GGN />
							</a>
							<a
								href={'https://www.experian.nl/'}
								target={'_blank'}
								rel={'noopener nofollow noreferrer'}
								title={'Experian Netherlands'}
							>
								<Experian />
							</a>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>

			<div className={getSectionClassNames('meta')} />
		</footer>
	);
};

export default React.memo(AcFooter);
