// Imports => React
import React, { memo } from 'react';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';

// Component
const AcReportLine = memo(({ label = null, value = null }) => {
  return (
    <AcRow className={'ac-report-line'}>
      {label && (
        <AcColumn xs={12} sm={label && value ? 6 : 12}>
          <span
            className={'ac-report-line__label'}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </AcColumn>
      )}

      {value && (
        <AcColumn xs={12} sm={label ? 6 : 12}>
          <span
            className={`ac-report-line__value ${!value ||
              (value === '-' && 'ac-report-line__value--blank')}`}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        </AcColumn>
      )}
    </AcRow>
  );
});

export default AcReportLine;
