// Imports => React
import React, { memo, useMemo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { VISUALS } from '@constants';

const _CLASSES = {
	MAIN: 'ac-loader',
	WRP: {
		MAIN: 'ac-loader-wrp',
		COVER: 'ac-loader-wrp--cover',
	},
	VISUAL: 'ac-loader__visual',
};

// Component
const AcLoader = ({ loading, Visual = VISUALS.LOADER, cover = true }) => {
	const getLoaderVisualClassNames = useMemo(() => {
		return clsx(_CLASSES.VISUAL);
	});

	const getLoaderClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	});

	const getLoaderWrpClassNames = useMemo(() => {
		return clsx(_CLASSES.WRP.MAIN, cover && _CLASSES.WRP.COVER);
	});

	return loading ? (
		<div className={getLoaderWrpClassNames}>
			<div className={getLoaderClassNames}>
				<Visual className={getLoaderVisualClassNames} />
			</div>
		</div>
	) : null;
};

export default memo(AcLoader);
