// Imports => MOBX
import { observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
} from '@utils';

const _default = {
	options: {},
	profile: {
		id: '',
		name: '',
		email: '',
		company: '',
	},
};

let app = {};

export class ProfileStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.PROFILE);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	profile = {};

	@computed
	get current_profile() {
		return this.profile;
	}

	@observable
	loading = {
		status: false,
		message: null,
	};

	@action
	setLoading(state, message) {
		this.loading = {
			status: state || false,
			message: message || 'Checking your profile',
		};
	}

	@action
	who_am_i = () => {
		this.setLoading(true);

		return app.store.api.profile
			.whoami()
			.then(response => {
				const _data = {
					...response.data,
					password: '',
					new_password: '',
					new_password_confirmation: '',
				};
				this.set(KEYS.PROFILE, response.data, true);
				this.set(KEYS.PROFILE_CLONE, _data);

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					delay: 5000,
					title: 'Failed to retreive your profile',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	set(target, value, store) {
		if (!target) return;
		if (!this[target]) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target] = value;

		if (store) {
			AcSaveState(target, this[target]);
		}
	}

	@action
	setValue(target, property, value) {
		if (!target) return;
		if (!this[target]) return;
		if (!property) return;
		if (typeof value === 'undefined' || value === null) return;

		this[target][property] = value;
		AcSaveState(target, this[target]);
	}

	@action
	reset(target) {
		if (!target) return;
		if (!this[target]) return;

		this[target] = _default[target];
		AcSaveState(target, this[target]);
	}
}

export default ProfileStore;
