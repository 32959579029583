import React, { useEffect, useMemo } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

// Imports => Config
import config from '@config';

// Imports => Constants
import { KEYS, TITLES } from '@constants';

// Imports => Utilities
import { AcSetDocumentTitle } from '@utils';

// Imports => Molecules
import AcDirectAccess from '@molecules/ac-direct-access/ac-direct-access.web';

// Imports => Components
import AcAuthForm from '@components/ac-auth-form/ac-auth-form.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

const AcHome = ({ store, match }) => {
	useEffect(() => {
		const { is_authorized } = store.auth;
		if (is_authorized) AcSetDocumentTitle(TITLES.HOME);
	}, []);

	const token = useMemo(() => {
		return match && match.params && match.params.token;
	}, [match]);

	const content = useMemo(() => {
		let result = [];

		if (config.site === KEYS.CHECK_SOCIAAL) {
			result = require('../../assets/_data/home/home.check-sociaal').default;
		} else {
			result = require('../../assets/_data/home/home.verhuur-signaal').default;
		}

		return result;
	}, [config.site]);

	return (
		<AcContainer className="view">
			<AcRow>
				<AcColumn xs={12} sm={12} md={6} className={'h-margin-bottom-30'}>
					{content &&
						content.map(item => (
							<AcRow key={item.uuid}>
								<AcColumn xs={12}>
									{item.title && (
										<AcHeading rank={item.title.rank}>
											{item.title.text}
										</AcHeading>
									)}
									{item.content && <AcRichContent content={item.content} />}
								</AcColumn>
							</AcRow>
						))}
				</AcColumn>
				<AcColumn
					xs={12}
					sm={12}
					md={{
						size: !store.auth.is_authorized ? 6 : 4,
						offset: !store.auth.is_authorized ? 0 : 2,
					}}
					className={'h-margin-bottom-25'}
				>
					{!store.auth.is_authorized && (
						<AcAuthForm path={match.path} token={token} />
					)}
					{store.auth.is_authorized && <AcDirectAccess />}
				</AcColumn>
			</AcRow>
		</AcContainer>
	);
};

export default inject('store')(observer(AcHome));
