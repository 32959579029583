export const TITLES = {
	BASE: 'GGN',
	FAQ: 'Veelgestelde vragen',
	HOME: 'Home',
	REPORT: 'Rapportage',
	TERMS_CONDITIONS: 'Algemene voorwaarden',
	VERIFY: 'Controleren',
	RESET_PASSWORD: 'Wachtwoord resetten',
	FORGOT_PASSWORD: 'Wachtwoord vergeten',
	LOGIN: 'Inloggen',
	SITE: {
		check_sociaal: 'Check Sociaal',
		verhuur_signaal: 'VerhuurSignaal',
	},
};
