import React, { memo, useEffect } from 'react';

// Imports => Constants
import { TITLES } from '@constants';

// Imports => Utilities
import { AcSetDocumentTitle } from '@utils';

// Imports => Molecules
import AcDirectAccess from '@molecules/ac-direct-access/ac-direct-access.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';

const AcTermsConditions = () => {
	useEffect(() => {
		AcSetDocumentTitle(TITLES.TERMS_CONDITIONS);
	}, []);

	return (
		<AcContainer className={'view'}>
			<AcRow>
				<AcColumn xs={12} sm={12} md={8} className={'h-margin-bottom-30'}>
					<AcRow>
						<AcColumn>
							<AcHeading rank={1}>Algemene voorwaarden</AcHeading>
						</AcColumn>
					</AcRow>
					<AcRow>
						<AcColumn>
							<div className={'ac-rich-content'}>
								<p>
									De informatie waartoe u na dit scherm toegang krijgt bevat
									persoonsgegevens die u onder voorwaarden beschikbaar gesteld
									worden. Een van die voorwaarden is dat de gegevens slechts
									gebruikt zullen worden binnen de doelstelling waarvoor deze
									verzameld zijn. Deze doelstellingen luiden:
								</p>
								<ul className="list">
									<li>
										Het op commerciële basis verwerken van gegevens over
										natuurlijke en / of rechtspersonen ter ondersteuning van
										organisaties die blootstaan aan kredietrisico
									</li>
									<li>
										Door de ontvangers te nemen beslissingen over het selecteren
										van potentiële handelspartners
									</li>
									<li>
										Het al dan niet onder bepaalde voorwaarden aangaan dan wel
										continueren dan wel beëindigen van handelstransacties /
										-relaties
									</li>
									<li>
										Het leveren van een bijdrage aan de signalering van misbruik
										en fraude in het financiële verkeer
									</li>
									<li>
										Het bepalen van verhaalsmogelijkheden en het traceren van
										personen
									</li>
									<li>
										Het leveren van een bijdrage aan voorkoming en signalering
										van fraude en andere criminaliteit
									</li>
									<li>
										Het al dan niet onder bepaalde voorwaarden aangaan dan wel
										continueren dan wel beëindigen van handelstransacties en
										arbeidsverhoudingen
									</li>
								</ul>
								<p>
									Bij gebleken of te vrezen misbruik, waaronder mede te verstaan
									het niet betrachten van uiterste voorzichtigheid t.a.v.
									informatie uit het Experian/GGN Informatiesysteem door
									gebruiker, zal onverwijld de toegang tot de informatie
									geblokkeerd worden.
								</p>
							</div>
						</AcColumn>
					</AcRow>
				</AcColumn>

				<AcColumn xs={12} sm={12} md={4} className={'h-margin-bottom-25'}>
					<AcDirectAccess />
				</AcColumn>
			</AcRow>
		</AcContainer>
	);
};

export default memo(AcTermsConditions);
