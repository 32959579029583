// Imports => React
import React from 'react';
import { Fade } from 'react-reveal';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Controller
import AcTextInputController from './ac-text-input.controller';

// Component
class AcTextInput extends AcTextInputController {
  buildError() {
    const { hasError } = this.state;
    return (
      <Fade duration={200}>
        <div className={this.getErrorClassNames()}>{hasError}</div>
      </Fade>
    );
  }

  buildInstructions() {
    const { instructions } = this.props;
    return (
      <div className={this.getInstructionsClassNames()}>{instructions}</div>
    );
  }

  buildTextInput() {
    const {
      value,
      disabled,
      readonly,
      maxLength,
      min,
      max,
      autocomplete,
    } = this.props;
    const { name, reference, type } = this.state;

    let pattern = null;
    if (type === 'number') pattern = '[-+]?[0-9]*[.,]?[0-9]+';

    let _props = {};

    if (AcIsSet(pattern)) _props = { ..._props, pattern };
    if (AcIsSet(min)) _props = { ..._props, min };
    if (AcIsSet(max)) _props = { ..._props, max };

    if (type === 'textarea') {
      return (
        <textarea
          type={type}
          name={name}
          id={reference}
          value={value}
          aria-labelledby={`label-${reference}`}
          placeholder={this.getPlaceholder()}
          className={this.getInputClassNames()}
          disabled={disabled}
          readOnly={readonly}
          autoComplete={autocomplete ? autocomplete : 'new-password'}
          tabIndex={0}
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
          ref={this.element}
          {..._props}
        />
      );
    }

    return (
      <input
        type={type === 'number' ? 'text' : type}
        name={name}
        id={reference}
        value={value}
        aria-labelledby={`label-${reference}`}
        placeholder={this.getPlaceholder()}
        className={this.getInputClassNames()}
        disabled={disabled}
        readOnly={readonly}
        pattern={pattern}
        autoComplete={autocomplete ? autocomplete : 'new-password'}
        tabIndex={0}
        maxLength={maxLength || 524288}
        onChange={this.onChange}
        onKeyUp={this.onKeyUp}
        ref={this.element}
        {..._props}
      />
    );
  }

  render() {
    const { label, disabled, readonly, required, instructions } = this.props;
    const { hasError, reference } = this.state;

    return (
      <div
        ref={node => (this.el = node)}
        className={this.getStyleClassNames()}
        disabled={disabled}
        readOnly={readonly}
      >
        {label && (
          <label
            id={`label-${reference}`}
            htmlFor={reference}
            className={this.getLabelClassNames()}
          >
            {label}
            <mark>{required ? ' *' : null}</mark>
          </label>
        )}
        {this.buildTextInput()}
        {hasError && this.buildError()}
        {!hasError && instructions && this.buildInstructions()}
      </div>
    );
  }
}

export default AcTextInput;
