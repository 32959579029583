// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-row',
	NOGUTTERS: 'ac-row--no-gutters',
	FORM: 'ac-row--form',
};

// Component
const AcGridRow = ({ className, noGutters, form, children }) => {
	const getStyleClassNames = () => {
		return clsx(
			_CLASSES.MAIN,
			noGutters && _CLASSES.NOGUTTERS,
			form && _CLASSES.FORM,
			className
		);
	};

	return <div className={getStyleClassNames()}>{children}</div>;
};

export default React.memo(AcGridRow);
