import React, { memo, useMemo, useEffect } from 'react';

// Imports => Config
import config from '@config';

// Imports => Constants
import { KEYS, TITLES } from '@constants';

// Imports => Utilities
import { AcSetDocumentTitle } from '@utils';

// Imports => Molecules
import AcExpandable from '@molecules/ac-expandable/ac-expandable.web';
import AcDirectAccess from '@molecules/ac-direct-access/ac-direct-access.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';

const AcFaq = () => {
	useEffect(() => {
		AcSetDocumentTitle(TITLES.FAQ);
	}, []);

	const content = useMemo(() => {
		let result = [];

		if (config.site === KEYS.CHECK_SOCIAAL) {
			result = require('../../assets/_data/faq/faq.check-sociaal').default;
		} else {
			result = require('../../assets/_data/faq/faq.verhuur-signaal').default;
		}

		return result;
	}, [config.site]);

	return (
		<AcContainer className="view">
			<AcRow>
				<AcColumn xs={12} sm={10} md={8} className={'h-margin-bottom-30'}>
					<AcRow className={'h-margin-bottom-30'}>
						<AcColumn>
							<AcHeading rank={1}>Veelgestelde vragen</AcHeading>
							<AcHeading rank={2}>Hoe kunnen we je helpen?</AcHeading>
						</AcColumn>
					</AcRow>

					<AcRow>
						<AcColumn>
							{content &&
								content.map(item => (
									<AcExpandable title={item.title} key={item.uuid}>
										<AcRichContent content={item.content} />
									</AcExpandable>
								))}
						</AcColumn>
					</AcRow>
				</AcColumn>

				<AcColumn xs={12} sm={6} md={4} className={'h-margin-bottom-25'}>
					<AcDirectAccess />
				</AcColumn>
			</AcRow>
		</AcContainer>
	);
};

export default memo(AcFaq);
