// Imports => MOBX
import { observable, computed, action } from 'mobx';
import moment from 'moment';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
	AcAutoLoad,
	AcAutoSave,
	AcSaveState,
	AcClearState,
	AcFormatErrorCode,
	AcFormatErrorMessage,
	AcIsUndefined,
} from '@utils';

let app = {};
let timer = null;

export class AuthStore {
	constructor(store) {
		AcAutoLoad(this, KEYS.ACCOUNT);
		AcAutoLoad(this, KEYS.ACCESS_TOKEN);
		AcAutoLoad(this, KEYS.EXPIRES_IN);
		AcAutoLoad(this, KEYS.EXPIRES_AT);
		AcAutoLoad(this, KEYS.REFRESH_TOKEN);
		AcAutoSave(this);

		app.store = store;
	}

	@observable
	access_token = null;

	@observable
	expires_in = null;

	@observable
	expires_at = null;

	@observable
	account = null;

	@observable
	loading = {
		status: false,
		message: undefined,
	};

	@computed
	get is_loading() {
		return this.loading.status;
	}

	@computed
	get current_access_token() {
		return this.access_token;
	}

	@computed
	get current_expires_at() {
		return this.expires_at;
	}

	@computed
	get current_account() {
		return this.account;
	}

	@computed
	get is_authorized() {
		let authorized = false;

		const account = this.current_account;
		const access_token = this.current_access_token;
		const expires_at = this.current_expires_at;
		const now = moment();
		let expired = true;

		if (expires_at) {
			expired = expires_at && moment(expires_at, 'X').isBefore(now);
		}

		// console.group('[store] Auth => Is Authorized');
		// console.log('Expires_at: ', moment(expires_at, 'X').format('LLLL'));
		// console.log('Now:', moment(now).format('LLLL'));
		// console.log('Is Expired: ', expired);

		authorized = account && access_token && !expired ? true : false;

		// console.log('Authorized: ', authorized);
		// console.groupEnd();

		return authorized === true;
	}

	@action
	setLoading = (state, message) => {
		this.loading = {
			status: state || false,
			message: message || false,
		};
	};

	@action
	clearAuthentication = async () => {
		await this.set(KEYS.ACCOUNT, null);
		await this.set(KEYS.ACCESS_TOKEN, null);
		await this.set(KEYS.REFRESH_TOKEN, null);
		await this.set(KEYS.EXPIRES_IN, null);
		await this.set(KEYS.EXPIRES_AT, null);
		AcClearState();
	};

	@action
	handleAuthentication = result => {
		if (result && result.access_token)
			this.set(KEYS.ACCESS_TOKEN, result.access_token);
		if (result && result.refresh_token)
			this.set(KEYS.REFRESH_TOKEN, result.refresh_token);
		if (result && result.expires_in) {
			const expires_at = moment()
				.add(result.expires_in)
				.format('X');
			this.set(KEYS.EXPIRES_IN, result.expires_in);
			this.set(KEYS.EXPIRES_AT, expires_at);
		}
		if (result) this.set(KEYS.ACCOUNT, result);
	};

	@action
	login = credentials => {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.login(credentials)
			.then(response => {
				this.handleAuthentication(response);

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				this.setLoading(false);
				this.clearAuthentication(error);

				app.store.toasters.add({
					variant: 'error',
					title: 'Inloggen is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				throw error;
			});
	};

	@action
	forgot_password = credentials => {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.forgot_password(credentials)
			.then(response => {
				app.store.toasters.add({
					variant: 'success',
					title: response.status || 'Nieuw wachtwoord is aangevraagd',
					description:
						'Er is een e-mail verstuurd naar het bij ons bekende e-mailadres. Klik op de link in de e-mail om een nieuw wachtwoord in te stellen.',
				});

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				app.store.toasters.add({
					variant: 'error',
					title: 'Nieuw wachtwoord aanvragen is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	reset_password = credentials => {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.reset_password(credentials)
			.then(response => {
				app.store.toasters.add({
					variant: 'success',
					title: 'Nieuw wachtwoord is opgeslagen',
				});

				this.setLoading(false);
				return response;
			})
			.catch(error => {
				this.clearAuthentication(error);

				app.store.toasters.add({
					variant: 'error',
					title: 'Nieuw wachtwoord opslaan is niet gelukt',
					description: AcFormatErrorMessage(error),
					code: AcFormatErrorCode(error),
				});

				this.setLoading(false);
				throw error;
			});
	};

	@action
	logout = () => {
		if (timer) clearTimeout(timer);

		this.clearAuthentication();
	};

	@action
	set = (key, value) => {
		if (AcIsUndefined(key)) return;
		if (AcIsUndefined(this[key])) return;

		this[key] = value;
		AcSaveState(key, value);
	};
}

export default AuthStore;
