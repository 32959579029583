import React, { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-reveal';

// Imports => Config
import config from '@config';

// Imports => Constants
import { KEYS, PATHS, ROUTES, TITLES, THEMES } from '@constants';

// Imports => Utilities
import {
	AcIsSet,
	AcIsEmptyString,
	AcIsNull,
	AcIsEmail,
	AcGetPasswordStrength,
	AcSetDocumentTitle,
} from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcDivider from '@atoms/ac-divider/ac-divider.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';
import AcPasswordStrength from '@atoms/ac-password-strength/ac-password-strength.web';

const AcAuthForm = ({ routing, store, path, token }) => {
	const [mode, setMode] = useState(KEYS.LOGIN);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState({
		[KEYS.EMAIL]: undefined,
		[KEYS.PASSWORD]: undefined,
	});

	useEffect(() => {
		console.log('config: ', config);
		if (
			AcIsSet(path) &&
			path === ROUTES.RESET_PASSWORD.path &&
			AcIsSet(token)
		) {
			handleSwitchMode(KEYS.RESET_PASSWORD);
		} else if (AcIsSet(path) && path === ROUTES.FORGOT_PASSWORD.path) {
			handleSwitchMode(KEYS.FORGOT_PASSWORD);
		} else {
			handleSwitchMode(KEYS.LOGIN);
		}
	}, []);

	const handleSwitchMode = key => {
		if (!AcIsSet(key)) return;

		handleResetAll();
		setMode(key);

		switch (key) {
			case KEYS.LOGIN:
				if (path !== PATHS.AUTH.LOGIN) handleChangePath(PATHS.AUTH.LOGIN);
				AcSetDocumentTitle(TITLES.LOGIN);
				break;

			case KEYS.FORGOT_PASSWORD:
				if (path !== PATHS.AUTH.FORGOT_PASSWORD)
					handleChangePath(PATHS.AUTH.FORGOT_PASSWORD);
				AcSetDocumentTitle(TITLES.FORGOT_PASSWORD);
				break;

			case KEYS.RESET_PASSWORD:
				if (path !== PATHS.AUTH.RESET_PASSWORD)
					handleChangePath(PATHS.AUTH.RESET_PASSWORD);
				AcSetDocumentTitle(TITLES.RESET_PASSWORD);
				break;

			default:
		}
	};

	const handleChangePath = route => {
		if (!route) return;
		if (!window || !window.history || !window.history.replaceState) return;
		window.history.replaceState(
			null,
			document.title,
			`${window.location.origin}${route}`
		);
	};

	const handleResetAll = () => {
		setUsername('');
		setPassword('');
		setErrors({
			[KEYS.EMAIL]: undefined,
			[KEYS.PASSWORD]: undefined,
		});
	};

	const handlePasswordReset = async event => {
		if (event && event.preventDefault) event.preventDefault();

		if (AcIsSet(username) && AcIsSet(password) && AcIsSet(token)) {
			const credentials = {
				email: username,
				password,
				password_confirmation: password,
				token,
			};

			await store.auth.reset_password(credentials).then(() => {
				handleSwitchMode(KEYS.LOGIN);
			});
		}
	};

	const handlePasswordForgot = async event => {
		if (event && event.preventDefault) event.preventDefault();

		if (AcIsSet(username)) {
			const credentials = {
				email: username,
			};

			await store.auth.forgot_password(credentials).then(() => {
				handleSwitchMode(KEYS.LOGIN);
			});
		}
	};

	const handleLogin = async event => {
		if (event && event.preventDefault) event.preventDefault();

		if (AcIsSet(username) && AcIsSet(password)) {
			const credentials = {
				username,
				password,
			};

			await store.auth.login(credentials).then(() => {
				const { replace } = routing.history;
				if (replace) replace(ROUTES.VERIFY.path);
			});
		}
	};

	const handleInputValidation = (name, value, type) => {
		let result = errors;

		switch (name) {
			case KEYS.EMAIL:
				if (!AcIsSet(value) || AcIsEmptyString(value)) {
					result[name] = 'Gebruikersnaam is een verplicht veld';
				} else if (!AcIsEmail(value)) {
					result[name] = 'Dit is geen geldig e-mailadres';
				} else {
					result[name] = null;
				}
				break;

			case KEYS.PASSWORD:
				if (!AcIsSet(value) || AcIsEmptyString(value)) {
					result[name] = 'Wachtwoord is een verplicht veld';
				} else if (
					mode === KEYS.RESET_PASSWORD &&
					AcGetPasswordStrength(value) < 5
				) {
					result[name] = 'Wachtwoord voldoet niet aan de voorwaarden';
				} else {
					result[name] = null;
				}
				break;

			default:
		}

		setErrors(result);

		return result[name];
	};

	const handleInputChange = (event, name, value, type) => {
		if (event && event.persist) event.persist();
		if (name === KEYS.EMAIL) setUsername(value);
		if (name === KEYS.PASSWORD) setPassword(value);
	};

	const getUsernameInputOptions = useMemo(() => {
		return {
			label: 'Gebruikersnaam',
			type: KEYS.TEXT,
			name: KEYS.EMAIL,
			value: username,
			required: true,
			validation: handleInputValidation,
			callback: handleInputChange,
		};
	}, [username]);

	const getPasswordInputOptions = useMemo(() => {
		return {
			label: 'Wachtwoord',
			type: KEYS.PASSWORD,
			name: KEYS.PASSWORD,
			value: password,
			required: true,
			validation: handleInputValidation,
			callback: handleInputChange,
		};
	}, [password]);

	return (
		<AcCard>
			<Fade key={mode}>
				<div className={'ac-card__content'}>
					{mode === KEYS.LOGIN && (
						<form
							method={'post'}
							autoComplete={'new-password'}
							onSubmit={handleLogin}
							name={'ac-form-login'}
						>
							<AcContainer>
								<AcRow className={'h-margin-bottom-10'}>
									<AcColumn>
										<AcHeading rank={2}>Log in</AcHeading>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn>
										<AcTextInput {...getUsernameInputOptions} />
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn>
										<AcTextInput {...getPasswordInputOptions} />
									</AcColumn>
								</AcRow>

								<AcRow className={'h-margin-bottom-10'}>
									<AcColumn xs={12} md={4}>
										<button
											type={'submit'}
											tabIndex={0}
											disabled={
												!AcIsNull(errors[KEYS.EMAIL]) ||
												!AcIsNull(errors[KEYS.PASSWORD]) ||
												store.auth.is_loading
											}
											className={'ac-button'}
										>
											Inloggen
										</button>
									</AcColumn>

									<AcColumn xs={12} md={8} className={'h-flex-h-align-end'}>
										<button
											type={'button'}
											disabled={store.auth.is_loading}
											className={'ac-button ac-button--transparent'}
											onClick={() => handleSwitchMode(KEYS.FORGOT_PASSWORD)}
										>
											Wachtwoord vergeten?
										</button>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn>
										<AcDivider
											theme={THEMES.LIGHT}
											className={'h-margin-top-10 h-margin-bottom-5'}
										/>
									</AcColumn>
								</AcRow>

								<AcRow className={'h-margin-top-20 h-margin-bottom-10'}>
									<AcColumn>
										<AcHeading rank={2}>Nog geen klant?</AcHeading>
									</AcColumn>
								</AcRow>

								<AcRow className={'h-margin-bottom-10'}>
									<AcColumn>
										<a
											href={config.register_uri}
											target={'_blank'}
											rel={'noopener nofollow noreferrer'}
											class={'ac-button'}
										>
											Meld je aan!
										</a>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn>
										<AcDivider
											theme={THEMES.LIGHT}
											className={'h-margin-top-10 h-margin-bottom-5'}
										/>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn className={'ac-rich-content'}>
										<p className={'h-text--italic'}>
											Problemen met inloggen of aanvragen? Mail naar{' '}
											<a href="mailto:sales@ggn.nl">sales@ggn.nl</a> of bel ons
											op <a href="tel:0883316678">088-3316678</a>.
										</p>
										<p className={'h-text--italic'}>
											Door in te loggen gaat u akkoord met de{' '}
											<Link to={ROUTES.TERMS_CONDITIONS.path}>
												algemene voorwaarden
											</Link>
											.
										</p>
									</AcColumn>
								</AcRow>
							</AcContainer>
						</form>
					)}
					{mode === KEYS.FORGOT_PASSWORD && (
						<form
							method={'post'}
							autoComplete={'new-password'}
							onSubmit={handlePasswordForgot}
							name={'ac-form-password-reset'}
						>
							<AcContainer>
								<AcRow className={'h-margin-bottom-10'}>
									<AcColumn>
										<AcHeading rank={2}>Wachtwoord vergeten</AcHeading>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn>
										<AcTextInput {...getUsernameInputOptions} />
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn xs={12} md={6}>
										<button
											type={'submit'}
											tabIndex={0}
											disabled={
												!AcIsNull(errors[KEYS.EMAIL]) || store.auth.is_loading
											}
											className={'ac-button'}
										>
											Wachtwoord aanvragen
										</button>
									</AcColumn>

									<AcColumn xs={12} md={6} className={'h-flex-h-align-end'}>
										<button
											type={'button'}
											disabled={store.auth.is_loading}
											className={'ac-button ac-button--transparent'}
											onClick={() => handleSwitchMode(KEYS.LOGIN)}
										>
											Terug naar inloggen
										</button>
									</AcColumn>
								</AcRow>
							</AcContainer>
						</form>
					)}
					{mode === KEYS.RESET_PASSWORD && (
						<form
							method={'post'}
							autoComplete={'new-password'}
							onSubmit={handlePasswordReset}
							name={'ac-form-password-reset'}
						>
							<AcContainer>
								<AcRow className={'h-margin-bottom-10'}>
									<AcColumn>
										<AcHeading rank={2}>Nieuw wachtwoord instellen</AcHeading>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn>
										<AcTextInput {...getUsernameInputOptions} />
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn>
										<AcDivider
											theme={THEMES.LIGHT}
											className={'h-margin-top-0 h-margin-bottom-20'}
										/>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn>
										<AcPasswordStrength password={password} />
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn>
										<AcTextInput {...getPasswordInputOptions} />
									</AcColumn>
								</AcRow>

								<AcRow className={'h-margin-bottom-10'}>
									<AcColumn xs={12} md={6}>
										<button
											type={'submit'}
											tabIndex={0}
											disabled={
												!AcIsNull(errors[KEYS.EMAIL]) ||
												!AcIsNull(errors[KEYS.PASSWORD]) ||
												store.auth.is_loading
											}
											className={'ac-button'}
										>
											Wachtwoord opslaan
										</button>
									</AcColumn>

									<AcColumn xs={12} md={6} className={'h-flex-h-align-end'}>
										<button
											type={'button'}
											disabled={store.auth.is_loading}
											className={'ac-button ac-button--transparent'}
											onClick={() => handleSwitchMode(KEYS.LOGIN)}
										>
											Naar inloggen
										</button>
									</AcColumn>
								</AcRow>
							</AcContainer>
						</form>
					)}
				</div>
			</Fade>

			<AcLoader loading={store.auth.is_loading} />
		</AcCard>
	);
};

export default inject('routing', 'store')(observer(AcAuthForm));
