// Imports => React
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, withRouter } from 'react-router-dom';
import { Fade } from 'react-reveal';
import clsx from 'clsx';

// Imports => Utilities
import { AcSetDocumentTitle } from '@utils';

// Imports => Constants
import { KEYS, ROUTES, DEFAULT_ROUTE, TITLES } from '@constants';

// Imports => Molecules
import AcPrivateRoute from '@molecules/ac-private-route/ac-private-route.web';
import AcToasterHoc from '@molecules/ac-toaster-hoc/ac-toaster-hoc.web';
import AcModal from '@molecules/ac-modal/ac-modal.web';

// Imports => Components
import AcHeader from '@components/ac-header/ac-header.web';
import AcFooter from '@components/ac-footer/ac-footer.web';
import AcActivityMonitor from '@components/ac-activity-monitor/ac-activity-monitor.web';

// Imports => Atoms
import AcErrorBoundary from '@atoms/ac-error-boundary/ac-error-boundary.web';

const _CLASSES = {
	MAIN: 'ac-main',
	AUTHORIZED: 'ac-main--authorized',
	ROUTE: {
		TRANSITION: 'ac-route__transition',
		SECTION: 'ac-route__section',
	},
	TRANSITIONGROUP: 'ac-route__transition-group',
	TRANSITIONGROUPHIDDEN: 'ac-route__transition-group--hidden',
	PAGETRANSITION: 'ac-route__transition',
	ROUTESECTION: 'ac-route__section',
};

let timeout = {
	enter: false,
	entered: false,
	exit: false,
	exited: false,
};

@inject('store')
@observer
class App extends React.Component {
	constructor(props) {
		super(props);

		this.store = props.store;
		this.route = props.location.pathname;

		this.$scroller = React.createRef();

		this.state = {
			onEndListener: false,
			animation: {
				mountOnEnter: true,
				unmountOnExit: true,
				appear: true,
				enter: false,
				exit: false,
				duration: 300,
				delay: 300,
			},
		};

		this.init = this.init.bind(this);
		this.handleRouteChanged = this.handleRouteChanged.bind(this);
		this.hideUIElements = this.hideUIElements.bind(this);

		this.handleEnterState = this.handleEnterState.bind(this);
		this.handleEnteredState = this.handleEnteredState.bind(this);
		this.handleExitState = this.handleExitState.bind(this);
		this.handleExitedState = this.handleExitedState.bind(this);
	}

	componentDidUpdate(nextProps, prevProps) {
		if (this.props.location.pathname !== this.route) {
			this.route = this.props.location.pathname;
			this.handleRouteChanged();
			if (this.store.auth.is_authorized) this.init();
		}
	}

	componentDidMount() {
		this.init();
	}

	componentWillUnmount() {
		this.removeEvents();
	}

	async init() {
		AcSetDocumentTitle(TITLES.HOME);
		if (this.store.auth.is_authorized) {
			await this.store.profile.who_am_i();
		}
	}

	handleRouteChanged(event) {
		this.hideUIElements();
	}

	handleEnterState() {
		if (timeout.entered) clearTimeout(timeout.entered);
		if (timeout.exit) clearTimeout(timeout.exit);
		if (timeout.exited) clearTimeout(timeout.exited);

		this.setState({
			animation: {
				...this.state.animation,
				appear: false,
				enter: true,
				exit: false,
			},
		});
	}

	handleEnteredState() {
		timeout.entered = setTimeout(() => {
			this.setState({
				animation: {
					...this.state.animation,
					appear: false,
					enter: false,
					exit: false,
				},
			});
		}, this.state.animation.delay);
	}

	handleExitState(node, done) {
		if (timeout.entered) clearTimeout(timeout.entered);
		if (timeout.exited) clearTimeout(timeout.exited);
		timeout.exit = setTimeout(() => {
			this.setState({
				animation: {
					...this.state.animation,
					appear: false,
					enter: false,
					exit: true,
				},
			});

			if (done) done();
		}, this.state.animation.delay);
	}

	handleExitedState() {
		if (timeout.exit) clearTimeout(timeout.exit);
		timeout.exited = setTimeout(() => {
			this.setState({
				animation: {
					...this.state.animation,
					appear: false,
					enter: false,
					exit: false,
				},
			});
		}, this.state.animation.delay);
	}

	hideUIElements(event) {
		this.store.ui.setState(KEYS.NAVIGATION, KEYS.VISIBLE, false);
		this.store.ui.setState(KEYS.MODAL, KEYS.VISIBLE, false);
	}

	getTransitionClassNames() {
		return clsx(_CLASSES.PAGETRANSITION);
	}

	getTransitionGroupClassNames() {
		const { ui } = this.store;

		const hidden = ui.navigation.visible;

		return clsx(
			_CLASSES.TRANSITIONGROUP,
			hidden && _CLASSES.TRANSITIONGROUPHIDDEN
		);
	}

	getRouteSectionClassNames() {
		return clsx(_CLASSES.ROUTESECTION, this.getTransitionGroupClassNames());
	}

	getMainClassNames() {
		return clsx(
			_CLASSES.MAIN,
			this.store.auth.is_authorized && _CLASSES.AUTHORIZED
		);
	}

	render() {
		const { location, store } = this.props;
		const { is_authorized } = store.auth;

		return (
			<AcErrorBoundary>
				<div className={this.getMainClassNames()}>
					<AcHeader />

					<section
						className={this.getRouteSectionClassNames()}
						id={KEYS.SCROLLER}
						ref={this.$scroller}
						onClick={this.hideUIElements}
					>
						<Fade key={location.pathname || null}>
							<Switch location={location}>
								{ROUTES &&
									Object.keys(ROUTES)
										.filter(route =>
											ROUTES[route].forbidden ? is_authorized : true
										)
										.map(route => (
											<AcPrivateRoute
												key={`route-${ROUTES[route].id}`}
												name={ROUTES[route].name}
												path={ROUTES[route].path}
												component={ROUTES[route].component}
												forbidden={ROUTES[route].forbidden}
												authorized={store.auth.is_authorized}
												store={store}
												exact
											/>
										))}
								{DEFAULT_ROUTE && (
									<AcPrivateRoute
										component={DEFAULT_ROUTE.component}
										forbidden={DEFAULT_ROUTE.forbidden}
										store={store}
									/>
								)}
							</Switch>
						</Fade>
					</section>

					<AcFooter />

					<AcModal {...store.ui.modal}>{store.ui.modal.body}</AcModal>

					<AcToasterHoc
						queue={this.store.toasters.queue}
						callback={this.store.toasters.remove}
					/>

					<AcActivityMonitor callback={this.store.auth.logout} />
				</div>
			</AcErrorBoundary>
		);
	}
}

export default withRouter(App);
