export const LOADING_MESSAGES = [
  'Loading...',
  'One moment please...',
  'Thanks for your patience...',
];

export const ADDITIONAL_ERROR_MESSAGE = {
  0: 'Controleer de postcode huisnummer combinatie en probeer opnieuw.',
  1: 'Controleer de toevoeging en probeer opnieuw.',
  2: '',
  3: 'Controleer de postcode huisnummer combinatie en probeer opnieuw.',
  4: '',
  5: '',
  6: '',
  7: 'Controleer de postcode en probeer opnieuw.',
  8: '',
  9: 'Controleer de postcode en probeer opnieuw.',
};
