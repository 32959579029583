// Imports => Config
import config from '@config';

// Imports => Constants
import { TITLES } from '@constants';

export const AcSetDocumentTitle = title => {
	const SITE_TITLE = TITLES.SITE[config.site];
	document.title = `${title} - ${SITE_TITLE} - ${TITLES.BASE}`;
};

export default AcSetDocumentTitle;
