// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';

export default [
	{
		uuid: AcUUID(),
		title: {
			rank: '1',
			text: 'VerhuurSignaal',
		},
	},
	{
		uuid: AcUUID(),
		title: {
			rank: '2',
			text:
				'Online screening van kandidaat-huurders, speciaal voor verhurende tussenpersonen',
		},
		content:
			'<p>Wanneer u als tussenpersoon een woning verhuurt voor een opdrachtgever wilt u zeker weten dat u een betrouwbare huurder binnenhaalt. Dat wil zeggen: een huurder zonder verkeerde bedoelingen of vervalste papieren, die zijn huurtermijnen trouw betaalt én bovendien de woning met de grootst mogelijke zorg behandelt.</p>',
	},
	{
		uuid: AcUUID(),
		title: {
			rank: '3',
			text: 'Financieel onderzoek als onderdeel van uw zorgplicht',
		},
		content:
			'<p>Zekerheid over dit alles is natuurlijk van het allergrootste belang voor de eigenaar van de woning, uw opdrachtgever, maar ook voor uzelf. Als verhurend tussenpersoon heeft u tenslotte een zorgplicht, die onder meer inhoudt dat u geacht wordt om de vermogens- of inkomenspositie van kandidaat-huurders te onderzoeken.</p>',
	},
	{
		uuid: AcUUID(),
		title: {
			rank: '3',
			text: 'Tijdig maatregelen nemen',
		},
		content:
			'<p>Een dergelijke screening kan bijzonder tijdrovend zijn. GGN biedt u hiervoor daarom een eenvoudig en effectief instrument: VerhuurSignaal. Een online hulpmiddel dat u in een paar muisklikken duidelijkheid verschaft over de betaalwaardigheid en de identiteit van de huurder. Zo weet u precies met wie u te maken heeft en kunt u, waar nodig, tijdig uw maatregelen nemen. Met als doel om problemen al bij voorbaat te voorkomen en de verhuurovereenkomsten en verhuurdossier te complementeren.</p>',
	},
	{
		uuid: AcUUID(),
		title: {
			rank: '3',
			text: 'Contactinformatie',
		},
		content:
			'<p>Meer weten over de mogelijkheden van VerhuurSignaal? Of wilt er meteen mee aan de slag? Uw GGN accountmanager vertelt u er graag alles over. Of neem nu direct contact op met <a href="mailto:sales-support@ggn.nl" rel="noopener nofollow noreferrer">sales@ggn.nl</a>. </p>',
	},
];
