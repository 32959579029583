import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-reveal';

// Imports => Constants
import { KEYS, ROUTES, THEMES, TITLES } from '@constants';

// Imports => Utilities
import {
	AcSetDocumentTitle,
	AcIsSet,
	AcIsEmptyString,
	AcIsPostalCode,
	AcIsNumeric,
	AcIsAlphaNumeric,
	AcIsAlphabetical,
} from '@utils';

// Imports => Molecules
import AcDirectAccess from '@molecules/ac-direct-access/ac-direct-access.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcDivider from '@atoms/ac-divider/ac-divider.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcCheckbox from '@atoms/ac-checkbox/ac-checkbox.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const AcVerify = ({ routing: { history }, store }) => {
	useEffect(() => {
		store.verify.reset(KEYS.SESSION);
		store.verify.reset(KEYS.REPORT);

		AcSetDocumentTitle(TITLES.VERIFY);
	}, []);

	const [errors, setErrors] = useState({
		initials: true,
		middle_name: false,
		last_name: true,
		date_of_birth_day: true,
		date_of_birth_month: true,
		date_of_birth_year: true,
		postal_code: true,
		house_number: true,
		house_number_addition: false,
		document_type: true,
		document_number: true,
	});

	const [credit_check, setCreditCheck] = useState(true);
	const [identity_check, setIdentityCheck] = useState(false);

	const [document_type, setDocumentType] = useState('');
	const [document_number, setDocumentNumber] = useState('');

	const [initials, setInitials] = useState('');
	const [middle_name, setMiddleName] = useState('');
	const [last_name, setLastName] = useState('');
	const [date_of_birth_day, setDateOfBirthDay] = useState('');
	const [date_of_birth_month, setDateOfBirthMonth] = useState('');
	const [date_of_birth_year, setDateOfBirthYear] = useState('');
	const [postal_code, setPostalCode] = useState('');
	const [house_number, setHouseNumber] = useState('');
	const [house_number_addition, setHouseNumberAddition] = useState('');

	const handleCheckboxChange = (event, name, value, selected) => {
		if (value && value === KEYS.CREDIT_CHECK) setCreditCheck(selected);
		if (value && value === KEYS.IDENTITY_CHECK) setIdentityCheck(selected);
	};

	const handleFormSubmit = event => {
		if (event && event.preventDefault) event.preventDefault();

		// dd-mm-yyyy
		const date_of_birth = `${date_of_birth_day}-${date_of_birth_month}-${date_of_birth_year}`;

		let data = {
			initials,
			middle_name,
			last_name,
			date_of_birth,
			postal_code,
			house_number,
			house_number_addition,
		};

		if (identity_check) {
			data = {
				...data,
				document_type,
				document_number,
			};
		}

		store.verify.request(data).then(() => {
			const { replace } = history;
			if (replace) replace(ROUTES.REPORT.path);
		});
	};

	const handleInputBlur = (event, name, value, type) => {
		let _value = value;

		switch (name) {
			case 'postal_code':
				_value =
					AcIsSet(_value) && !AcIsEmptyString(_value)
						? _value.toUpperCase()
						: _value;
				setPostalCode(_value);
				break;

			default:
		}
	};

	const handleInputChange = (event, name, value, type) => {
		let _value = value;

		switch (name) {
			case 'initials':
				setInitials(_value);
				break;

			case 'middle_name':
				setMiddleName(_value);
				break;

			case 'last_name':
				setLastName(_value);
				break;

			case 'date_of_birth_day':
				setDateOfBirthDay(_value);
				break;

			case 'date_of_birth_month':
				setDateOfBirthMonth(_value);
				break;

			case 'date_of_birth_year':
				setDateOfBirthYear(_value);
				break;

			case 'postal_code':
				_value = _value.replace(/\s/g, '');
				setPostalCode(_value);
				break;

			case 'house_number':
				_value = _value.replace(/\s/g, '');
				setHouseNumber(_value);
				break;

			case 'house_number_addition':
				setHouseNumberAddition(_value);
				break;

			case 'document_type':
				setDocumentType(_value);
				break;

			case 'document_number':
				setDocumentNumber(_value);
				break;

			default:
		}

		if (event && event.target && event.target.maxLength) {
			const { maxLength } = event.target;
			if (_value.length === maxLength && !errors[name]) {
				const $inputs = document.querySelectorAll('input');

				let n = 0;
				let len = $inputs.length;
				let next = false;

				for (n; n < len; n++) {
					const $input = $inputs[n];

					if (next) {
						window.requestAnimationFrame(() => {
							$input.focus();
						});
						break;
					}

					if ($input.name === name) {
						next = true;
					}
				}
			}
		}
	};

	const handleInputValidation = useCallback((name, value, type) => {
		let result = errors;

		switch (name) {
			case 'initials':
				result[name] = false;

				if (!AcIsSet(value) || AcIsEmptyString(value)) {
					result[name] = 'Voorletters is een verplicht veld';
				} else if (!AcIsAlphabetical(value)) {
					result[name] = 'Dit zijn geen geldige voorletters';
				}
				break;

			case 'middle_name':
				result[name] = false;

				if (
					AcIsSet(value) &&
					!AcIsEmptyString(value) &&
					!AcIsAlphabetical(value)
				) {
					result[name] = 'Dit is geen geldig tussenvoegsel';
				}
				break;

			case 'last_name':
				result[name] = false;

				if (!AcIsSet(value) || AcIsEmptyString(value)) {
					result[name] = 'Achternaam is een verplicht veld';
				} else if (!AcIsAlphabetical(value)) {
					result[name] = 'Dit is geen geldige achternaam';
				}
				break;

			case 'date_of_birth_day':
				result[name] = false;

				if (!AcIsSet(value) || AcIsEmptyString(value)) {
					result[name] = 'Dag is een verplicht veld';
				} else if (!AcIsNumeric(value)) {
					result[name] = 'Dit is geen geldige dag';
				} else if (parseInt(value, 10) <= 0 || parseInt(value, 10) > 31) {
					result[name] = 'Dit is geen geldige dag';
				}
				break;

			case 'date_of_birth_month':
				result[name] = false;

				if (!AcIsSet(value) || AcIsEmptyString(value)) {
					result[name] = 'Maand is een verplicht veld';
				} else if (!AcIsNumeric(value)) {
					result[name] = 'Dit is geen geldige maand';
				} else if (parseInt(value, 10) <= 0 || parseInt(value, 10) > 12) {
					result[name] = 'Dit is geen geldige maand';
				}
				break;

			case 'date_of_birth_year':
				result[name] = false;
				const current_year = new Date().getFullYear();

				if (!AcIsSet(value) || AcIsEmptyString(value)) {
					result[name] = 'Jaar is een verplicht veld';
				} else if (!AcIsNumeric(value)) {
					result[name] = 'Dit is geen geldig jaar';
				} else if (
					parseInt(value, 10) <= 1500 ||
					parseInt(value, 10) > current_year
				) {
					result[name] = 'Dit is geen geldig jaar';
				}
				break;

			case 'postal_code':
				result[name] = false;

				if (!AcIsSet(value) || AcIsEmptyString(value)) {
					result[name] = 'Postcode is een verplicht veld';
				} else if (!AcIsPostalCode(value)) {
					result[name] = 'Dit is geen geldige postcode';
				}
				break;

			case 'house_number':
				result[name] = false;

				if (!AcIsSet(value) || AcIsEmptyString(value)) {
					result[name] = 'Huisnummer is een verplicht veld';
				} else if (!AcIsNumeric(value)) {
					result[name] = 'Dit is geen geldig huisnummer';
				}
				break;

			case 'house_number_addition':
				result[name] = false;

				if (
					AcIsSet(value) &&
					!AcIsEmptyString(value) &&
					!AcIsAlphaNumeric(value)
				) {
					result[name] = 'Dit is geen geldige huisnummer toevoeging';
				}
				break;

			case 'document_type':
				result[name] = false;

				if (identity_check) {
					result[name] =
						!AcIsSet(value) || AcIsEmptyString(value)
							? 'Soort document is een verplicht veld'
							: false;
				}
				break;

			case 'document_number':
				result[name] = false;

				if (identity_check) {
					if (!AcIsSet(value) || AcIsEmptyString(value)) {
						result[name] = 'Documentnummer is een verplicht veld';
					} else if (!AcIsAlphaNumeric(value)) {
						result[name] = 'Dit is geen geldig documentnummer';
					}
				}
				break;

			default:
		}

		setErrors(result);

		return result[name];
	});

	const hasErrors = () => {
		let result = false;

		for (let key in errors) {
			if (errors[key]) {
				if (
					(key === 'document_number' || key === 'document_type') &&
					!identity_check
				) {
					result = false;
				} else {
					result = true;
				}
				break;
			}
		}

		return result;
	};

	const getInitialsInputOptions = useMemo(() => {
		return {
			type: 'text',
			label: 'Voorletters',
			name: 'initials',
			value: initials,
			autocomplete: 'off',
			required: true,
			validation: handleInputValidation,
			callback: handleInputChange,
		};
	}, [initials]);

	const getMiddleNameInputOptions = useMemo(() => {
		return {
			type: 'text',
			label: 'Tussenvoegsel',
			name: 'middle_name',
			value: middle_name,
			autocomplete: 'off',
			validation: handleInputValidation,
			callback: handleInputChange,
		};
	}, [middle_name]);

	const getLastNameInputOptions = useMemo(() => {
		return {
			type: 'text',
			label: 'Achternaam',
			name: 'last_name',
			value: last_name,
			autocomplete: 'off',
			required: true,
			validation: handleInputValidation,
			callback: handleInputChange,
		};
	}, [last_name]);

	const getDateOfBirthDayInputOptions = useMemo(() => {
		return {
			type: 'number',
			placeholder: 'Dag',
			name: 'date_of_birth_day',
			value: date_of_birth_day,
			autocomplete: 'off',
			maxLength: 2,
			min: 1,
			max: 31,
			required: true,
			validation: handleInputValidation,
			callback: handleInputChange,
		};
	}, [date_of_birth_day]);

	const getDateOfBirthMonthInputOptions = useMemo(() => {
		return {
			type: 'number',
			placeholder: 'Maand',
			name: 'date_of_birth_month',
			value: date_of_birth_month,
			autocomplete: 'off',
			maxLength: 2,
			min: 1,
			max: 12,
			required: true,
			validation: handleInputValidation,
			callback: handleInputChange,
		};
		// (event, name, value, type) => setDateOfBirthMonth(value),
	}, [date_of_birth_month]);

	const getDateOfBirthYearInputOptions = useMemo(() => {
		const current_year = new Date().getFullYear();
		return {
			type: 'number',
			placeholder: 'Jaar',
			name: 'date_of_birth_year',
			value: date_of_birth_year,
			autocomplete: 'off',
			maxLength: 4,
			min: 1500,
			max: current_year,
			required: true,
			validation: handleInputValidation,
			callback: handleInputChange,
		};
	}, [date_of_birth_year]);

	const getPostalCodeInputOptions = useMemo(() => {
		return {
			type: 'text',
			label: 'Postcode',
			name: 'postal_code',
			value: postal_code,
			autocomplete: 'off',
			maxLength: 6,
			required: true,
			validation: handleInputValidation,
			onBlur: handleInputBlur,
			callback: handleInputChange,
		};
	}, [postal_code]);

	const getHouseNumberInputOptions = useMemo(() => {
		return {
			type: 'number',
			label: 'Huisnummer',
			name: 'house_number',
			value: house_number,
			autocomplete: 'off',
			required: true,
			validation: handleInputValidation,
			callback: handleInputChange,
		};
	}, [house_number]);

	const getHouseNumberAdditionInputOptions = useMemo(() => {
		return {
			type: 'text',
			label: 'Toevoeging',
			name: 'house_number_addition',
			value: house_number_addition,
			autocomplete: 'off',
			validation: handleInputValidation,
			callback: handleInputChange,
		};
	}, [house_number_addition]);

	const getDocumentTypeSelectBoxOptions = useMemo(() => {
		const options = [
			{
				label: 'Nederlandse Identiteitskaart',
				value: KEYS.IDENTITY_CARD,
			},
			{
				label: 'Paspoort',
				value: KEYS.PASSPORT,
			},
			{
				label: 'Rijbewijs',
				value: KEYS.DRIVERS_LICENSE,
			},
		];

		return {
			placeholder: '- kies een type document',
			name: 'document_type',
			value: document_type,
			options,
			required: identity_check,
			validation: handleInputValidation,
			disabled: !identity_check,
			callback: handleInputChange,
		};
	}, [identity_check, document_type]);

	const getDocumentNumberInputOptions = useMemo(() => {
		return {
			type: 'text',
			label: 'Documentnummer',
			name: 'document_number',
			value: document_number,
			autocomplete: 'off',
			required: identity_check,
			validation: handleInputValidation,
			disabled: !identity_check,
			callback: handleInputChange,
		};
	}, [identity_check, document_number]);

	return (
		<AcContainer className="view">
			<AcRow>
				<AcColumn xs={12} sm={12} md={8}>
					<AcRow>
						<AcColumn>
							<AcHeading rank={1}>Controleren</AcHeading>
						</AcColumn>
					</AcRow>

					<AcRow>
						<AcColumn xs={12} sm={10} md={8} className={'h-margin-bottom-35'}>
							<form
								method={'post'}
								autoComplete={'new-password'}
								onSubmit={handleFormSubmit}
								name={'ac-form-verify-request'}
							>
								<AcRow>
									<AcColumn>
										<AcHeading rank={2}>Wat wilt u controleren?</AcHeading>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn xs={12} sm={10}>
										<AcCheckbox
											value={KEYS.CREDIT_CHECK}
											checked={credit_check}
											callback={handleCheckboxChange}
											disabled
										>
											Betaalgedrag
										</AcCheckbox>
									</AcColumn>

									<AcColumn xs={12} sm={10}>
										<AcCheckbox
											value={KEYS.IDENTITY_CHECK}
											checked={identity_check}
											callback={handleCheckboxChange}
										>
											Identiteit
										</AcCheckbox>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn xs={12} sm={10}>
										<AcDivider
											theme={THEMES.LIGHT}
											className={'h-margin-top-15 h-margin-bottom-30'}
										/>
									</AcColumn>
								</AcRow>

								{identity_check && (
									<Fade key={identity_check}>
										<>
											<AcRow>
												<AcColumn>
													<AcHeading rank={2}>
														Welk document wilt u controleren?
													</AcHeading>
												</AcColumn>
											</AcRow>

											<AcRow>
												<AcColumn xs={12}>
													<label
														className={`ac-text-input__label h-margin-bottom-8`}
													>
														Type document <mark>*</mark>
													</label>
												</AcColumn>
											</AcRow>

											<AcRow className={'h-z-index--on-top'}>
												<AcColumn
													xs={12}
													sm={10}
													className={'h-margin-bottom-20'}
												>
													<AcSelectBox {...getDocumentTypeSelectBoxOptions} />
												</AcColumn>
											</AcRow>

											<AcRow>
												<AcColumn xs={12} sm={10}>
													<AcTextInput {...getDocumentNumberInputOptions} />
												</AcColumn>
											</AcRow>

											<AcRow>
												<AcColumn xs={12} sm={10}>
													<AcDivider
														className={'h-margin-top-15 h-margin-bottom-30'}
													/>
												</AcColumn>
											</AcRow>
										</>
									</Fade>
								)}

								<AcRow>
									<AcColumn>
										<AcHeading rank={2}>Wie wilt u controleren?</AcHeading>
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn xs={6} sm={5}>
										<AcTextInput {...getInitialsInputOptions} />
									</AcColumn>
									<AcColumn xs={6} sm={5}>
										<AcTextInput {...getMiddleNameInputOptions} />
									</AcColumn>
								</AcRow>
								<AcRow>
									<AcColumn xs={12} sm={10}>
										<AcTextInput {...getLastNameInputOptions} />
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn xs={12}>
										<label className={`ac-text-input__label h-margin-bottom-8`}>
											Geboortedatum <mark>*</mark>
										</label>
									</AcColumn>

									<AcColumn xs={4} sm={3}>
										<AcTextInput {...getDateOfBirthDayInputOptions} />
									</AcColumn>

									<AcColumn xs={4} sm={4}>
										<AcTextInput {...getDateOfBirthMonthInputOptions} />
									</AcColumn>

									<AcColumn xs={4} sm={3}>
										<AcTextInput {...getDateOfBirthYearInputOptions} />
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn xs={12} sm={10}>
										<AcTextInput {...getPostalCodeInputOptions} />
									</AcColumn>
								</AcRow>

								<AcRow>
									<AcColumn xs={6} sm={5}>
										<AcTextInput {...getHouseNumberInputOptions} />
									</AcColumn>
									<AcColumn xs={6} sm={5}>
										<AcTextInput {...getHouseNumberAdditionInputOptions} />
									</AcColumn>
								</AcRow>

								<AcRow className={'h-margin-bottom-10'}>
									<AcColumn xs={12} md={6}>
										<button
											type={'submit'}
											disabled={hasErrors()}
											className={'ac-button'}
										>
											Nu controleren
										</button>
									</AcColumn>
								</AcRow>

								<AcLoader loading={store.verify.is_loading} />
							</form>
						</AcColumn>
					</AcRow>
				</AcColumn>

				<AcColumn xs={12} sm={6} md={4} className={'h-margin-bottom-25'}>
					<AcDirectAccess />
				</AcColumn>
			</AcRow>
		</AcContainer>
	);
};

export default inject('routing', 'store')(observer(AcVerify));
