// Imports => React
import React, { useEffect } from 'react';
import config from '@config';

const AcActivityMonitor = ({ callback }) => {
	let timer = null;
	let timestamp = new Date().getTime();

	useEffect(() => {
		if (config.autologout && config.autologout.active) {
			addEvents();
			checkActivity();
		}

		return () => {
			removeEvents();
		};
	});

	const getDifference = (dt2, dt1) => {
		var diff = (dt2.getTime() - dt1.getTime()) / 1000;
		diff /= 60;
		return Math.abs(Math.round(diff));
	};

	const checkActivity = () => {
		if (timer) clearTimeout(timer);

		// Check for activity every n seconds
		// n = 10
		timer = setTimeout(() => {
			const now = new Date();
			const end = new Date(timestamp);

			const difference = getDifference(now, end);

			if (difference >= config.autologout.time) {
				if (timer) clearTimeout(timer);
				if (callback) callback();
			} else {
				checkActivity();
			}
		}, 10 * 1000);
	};

	const updateActivity = () => {
		timestamp = new Date().getTime();
	};

	const addEvents = () => {
		window.addEventListener('mousemove', updateActivity, false);
		window.addEventListener('keydown', updateActivity, false);
	};

	const removeEvents = () => {
		window.removeEventListener('mousemove', updateActivity, false);
		window.removeEventListener('keydown', updateActivity, false);
	};

	return null;
};

export default React.memo(AcActivityMonitor);
