// Imports => React
import React, { useEffect } from 'react';
import clsx from 'clsx';

// Imports => Utils
import { AcIsObject } from '@utils';

const _CLASSES = {
	MAIN: 'ac-toaster',
	PROGRESS: 'ac-toaster__progress',
	TYPES: {
		DEFAULT: 'ac-toaster--default',
		INFO: 'ac-toaster--info',
		SUCCESS: 'ac-toaster--success',
		WARNING: 'ac-toaster--warning',
		ERROR: 'ac-toaster--error',
	},
	BODY: 'ac-toaster__body',
	ICON: {
		MAIN: 'ac-icon ac-toaster__icon',
		WRP: 'ac-toaster__icon-wrp',
		DEFAULT: 'ac-icon--message-text-outline',
		INFO: 'ac-icon--information-outline',
		SUCCESS: 'ac-icon--checkbox-marked-circle-outline',
		WARNING: 'ac-icon--alert-outline',
		ERROR: 'ac-icon--alert-circle-outline',
	},
	CONTENT: {
		MAIN: 'ac-toaster__content',
		TITLE: 'ac-toaster__title',
		DESCRIPTION: 'ac-toaster__description',
		CODE: 'ac-toaster__code',
	},
	CLOSE: {
		ICON: 'ac-icon ac-icon--close ac-toaster__close-icon',
		WRP: 'ac-toaster__close-icon-wrp',
	},
};

const AcToaster = ({
	expires,
	variant,
	delay,
	id,
	title,
	description,
	code,
	closeable = true,
	callback,
}) => {
	let interval = null;

	useEffect(() => {
		startCountdown();

		return () => {
			close();
		};
	});

	const startCountdown = () => {
		if (interval) clearInterval(interval);
		interval = setInterval(() => {
			const now = new Date().getTime();

			if (now >= expires) {
				if (interval) clearInterval(interval);
				if (callback) callback(id);
			}
		}, 1000);
	};

	const close = () => {
		if (interval) clearInterval(interval);
		if (callback) callback(id);
	};

	const getCloseIconClassNames = () => {
		return clsx(_CLASSES.CLOSE.ICON);
	};

	const getCloseWrpClassNames = () => {
		return clsx(_CLASSES.CLOSE.WRP);
	};

	const getCodeClassNames = () => {
		return clsx(_CLASSES.CONTENT.CODE);
	};

	const getDescriptionClassNames = () => {
		return clsx(_CLASSES.CONTENT.DESCRIPTION);
	};

	const getTitleClassNames = () => {
		return clsx(_CLASSES.CONTENT.TITLE);
	};

	const getContentWrpClassNames = () => {
		return clsx(_CLASSES.CONTENT.MAIN);
	};

	const getIconClassNames = () => {
		return clsx(
			_CLASSES.ICON.MAIN,
			variant && _CLASSES.ICON[variant.toUpperCase()]
		);
	};

	const getIconWrpClassNames = () => {
		return clsx(_CLASSES.ICON.WRP);
	};

	const getBodyClassNames = () => {
		return clsx(_CLASSES.BODY);
	};

	const getProgressClassNames = () => {
		return clsx(_CLASSES.PROGRESS);
	};

	const getStyleClassNames = () => {
		return clsx(
			_CLASSES.MAIN,
			variant && _CLASSES.TYPES[variant.toUpperCase()]
		);
	};

	const getStyleProperties = () => {
		return {
			transitionDuration: `${delay}ms`,
		};
	};

	return (
		<div className={getStyleClassNames()} id={id}>
			<div className={getBodyClassNames()}>
				<div className={getIconWrpClassNames()}>
					<i className={getIconClassNames()} />
				</div>

				<div className={getContentWrpClassNames()}>
					{title && (
						<div className={getTitleClassNames()}>
							{AcIsObject(title) && title.string}
							{!AcIsObject(title) && title}
						</div>
					)}
					{description && (
						<div
							className={getDescriptionClassNames()}
							dangerouslySetInnerHTML={{
								__html: description,
							}}
						/>
					)}

					{code && (
						<div className={getCodeClassNames()}>[Error code: {code}]</div>
					)}
				</div>
				{closeable && (
					<div className={getCloseWrpClassNames()} onClick={close}>
						<div className={getCloseIconClassNames()} />
					</div>
				)}
			</div>
			<div className={getProgressClassNames()} style={getStyleProperties()} />
		</div>
	);
};

export default React.memo(AcToaster);
