import React, { useEffect, useMemo, useCallback } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import moment from 'moment/min/moment-with-locales';

// Imports => Constants
import { ICONS, KEYS, ROUTES, THEMES, TITLES } from '@constants';

// Imports => Utilities
import { AcSetDocumentTitle, AcIsSet } from '@utils';

// Imports => Molecules
import AcDirectAccess from '@molecules/ac-direct-access/ac-direct-access.web';
import AcReportLine from '@molecules/ac-report-line/ac-report-line.web';
import AcReportResult from '@molecules/ac-report-result/ac-report-result.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcDivider from '@atoms/ac-divider/ac-divider.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const AcReport = ({ store, routing: { history } }) => {
  useEffect(() => {
    AcSetDocumentTitle(TITLES.REPORT);

    const { current_session, current_report } = store.verify;

    if (!AcIsSet(current_session) || !AcIsSet(current_report)) {
      const { replace } = history;
      if (replace) replace(ROUTES.VERIFY.path);
    }

    return () => {
      store.verify.reset(KEYS.SESSION);
      store.verify.reset(KEYS.REPORT);
    };
  }, []);

  const handleBackToVerify = () => {
    if (history && history.replace) history.replace(ROUTES.VERIFY.path);
  };

  const handleDownload = async event => {
    if (store.verify.is_loading) return;
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();

    await store.verify.download();
  };

  const formattedName = useMemo(() => {
    const { current_session } = store.verify;
    if (!current_session) return null;

    const { initials, middle_name, last_name } = current_session;

    let collection = [];

    if (initials) collection.push(initials);
    if (middle_name) collection.push(middle_name);
    if (last_name) collection.push(last_name);

    return collection.join(' ');
  });

  const formattedLevel = useCallback(score => {
    let level = 'Er is een <em>Onbekend risico</em> geconstateerd.';

    switch (score) {
      case 'green':
      case 'low':
        level = 'Er is een <em>Laag risico</em> risico geconstateerd.';
        break;

      case 'orange':
      case 'medium':
        level = 'Er is een <em>Gemiddeld risico</em> risico geconstateerd';
        break;

      case 'red':
      case 'high':
        level = 'Er is een <em>Hoog risico</em> risico geconstateerd';
        break;

      default:
    }

    return level;
  });

  const renderReportDate = useMemo(() => {
    const { current_report } = store.verify;
    if (!current_report) return null;

    const { date, time } = current_report.session;

    moment.locale('nl-NL');
    const formatted_date = moment(date, 'DD-MM-YYYY').format('DD MMMM YYYY');
    const date_time = `${formatted_date}, ${time}`;

    return <AcReportLine label={'Datum rapport'} value={date_time} />;
  });

  const renderDateOfBirth = useMemo(() => {
    const { current_report } = store.verify;
    if (!current_report) return null;

    const { date_of_birth } = current_report.session;

    moment.locale('nl-NL');
    const date = moment(date_of_birth, 'DD-MM-YYYY').format('DD MMMM YYYY');

    return <AcReportLine label={'Geboortedatum'} value={date} />;
  });

  const renderLastKnownAddress = useMemo(() => {
    const { current_report } = store.verify;
    if (!current_report) return null;

    const {
      address: street,
      house_number,
      house_number_addition,
      postal_code,
      city,
    } = current_report.address;

    let result = '';

    if (street) result += street;
    if (house_number) result += ` ${house_number}`;
    if (house_number_addition) result += `${house_number_addition}`;

    if (postal_code) result += '<br/>';
    if (postal_code) result += postal_code;
    if (postal_code && city) result += ', ';
    if (city) result += city;

    return (
      <AcReportLine
        label={'Laatste adres'}
        value={result === '' ? '-' : result}
      />
    );
  });

  const renderDoneChecks = useMemo(() => {
    const { current_report } = store.verify;
    if (!current_report) return null;

    const { id_check } = current_report;

    if (!id_check) {
      return (
        <AcReportLine label={'Uitgevoerde controles'} value={'Betaalgedrag'} />
      );
    } else if (id_check) {
      return (
        <AcReportLine
          label={'Uitgevoerde controles'}
          value={'Betaalgedrag & Identiteit'}
        />
      );
    }
  });

  const renderResult = useMemo(() => {
    const { current_report } = store.verify;
    if (!current_report) return null;
    const { risk } = current_report.score;
    const result = current_report.result;

    return <AcReportResult score={risk} currentResult={result} />;
  });

  const renderPaymentCheck = useMemo(() => {
    const { current_report } = store.verify;
    if (!current_report) return null;

    const { risk } = current_report.score;
    const risk_message = formattedLevel(risk);

    return <AcReportLine label={risk_message} />;
  });

  const renderIdentityCheck = useMemo(() => {
    const { current_session, current_report } = store.verify;

    if (!current_report) return null;

    if (!current_report.id_check) {
      return (
        <AcReportLine
          label={'Er is <em>geen</em> identiteitscontrole uitgevoerd.'}
        />
      );
    }

    const { message } = current_report.identity;
    const { document_number, document_type } = current_session;
    let render = [];

    if (document_number && document_type) {
      const type_of_document =
        document_type === 'VN'
          ? 'Nederlandse Identiteitskaart'
          : document_type === 'PP'
          ? 'Paspoort'
          : document_type === 'RB'
          ? 'Rijbewijs'
          : 'Onbekend';

      render.push(
        <AcReportLine label={'Documentsoort'} value={type_of_document} />
      );
      render.push(
        <AcReportLine label={'Documentnummer'} value={document_number} />
      );
    }

    render.push(<AcReportLine label={'Status'} value={message} />);

    return render;
  });

  return (
    <AcContainer className="view">
      <AcRow>
        <AcColumn xs={12} sm={12} md={8}>
          <AcRow>
            <AcColumn>
              <button
                className={'ac-button ac-button--light'}
                onClick={handleBackToVerify}
              >
                <AcIcon icon={ICONS.ARROW_LEFT} />
                <span>Nieuwe aanvraag</span>
              </button>

              <button
                className={`ac-button${
                  store.verify.is_loading ? ' ac-button--loading' : ''
                }`}
                disabled={store.verify.is_loading}
                onClick={handleDownload}
              >
                <span>Download PDF</span>
                <span className={'ac-button__loader'} />
                <AcIcon icon={ICONS.DOWNLOAD} />
              </button>
            </AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn>
              <AcDivider theme={THEMES.LIGHT} className={'h-margin-y-25'} />
            </AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn>
              <AcHeading rank={1}>{`Rapport van ${formattedName}`}</AcHeading>
            </AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn xs={12} sm={10} md={6}>
              {renderReportDate}
              {renderDoneChecks}
              {renderDateOfBirth}
              {renderLastKnownAddress}
            </AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn>
              <AcDivider theme={THEMES.LIGHT} className={'h-margin-y-25'} />
            </AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn>
              <AcHeading rank={3}>Resultaat</AcHeading>
            </AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn xs={12}>{renderResult}</AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn xs={12} sm={6} md={6}>
              <div className={'ac-report-box'}>
                <AcRow>
                  <AcColumn>
                    <AcHeading rank={3}>Betaalgedrag</AcHeading>
                  </AcColumn>
                </AcRow>

                <AcRow>
                  <AcColumn>{renderPaymentCheck}</AcColumn>
                </AcRow>
              </div>
            </AcColumn>

            <AcColumn xs={12} sm={6} md={6}>
              <div className={'ac-report-box'}>
                <AcRow>
                  <AcColumn>
                    <AcHeading rank={3}>Identiteit</AcHeading>
                  </AcColumn>
                </AcRow>

                <AcRow>
                  <AcColumn>{renderIdentityCheck}</AcColumn>
                </AcRow>
              </div>
            </AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn xs={12} sm={6} md={6}>
              <div className={'ac-report-box'}>
                <AcRow>
                  <AcColumn>
                    <AcHeading rank={3}>Registratie op persoon</AcHeading>
                  </AcColumn>
                </AcRow>

                <AcRow>
                  <AcColumn>
                    <AcReportLine
                      label={'Incassoregistraties'}
                      value={'<em>Er zijn geen registraties gevonden</em>'}
                    />
                    <AcReportLine
                      label={'Faillissement'}
                      value={'<em>Er zijn geen registraties gevonden</em>'}
                    />
                    <AcReportLine
                      label={'Surseance van betaling'}
                      value={'<em>Er zijn geen registraties gevonden</em>'}
                    />
                    <AcReportLine
                      label={'WSNP'}
                      value={'<em>Er zijn geen registraties gevonden</em>'}
                    />
                  </AcColumn>
                </AcRow>
              </div>
            </AcColumn>

            <AcColumn xs={12} sm={6} md={6}>
              <div className={'ac-report-box'}>
                <AcRow>
                  <AcColumn>
                    <AcHeading rank={3}>Registratie op adres</AcHeading>
                  </AcColumn>
                </AcRow>

                <AcRow>
                  <AcColumn>
                    <AcReportLine
                      label={'Incassoregistraties'}
                      value={'<em>Er zijn geen registraties gevonden</em>'}
                    />
                    <AcReportLine
                      label={'Faillissement'}
                      value={'<em>Er zijn geen registraties gevonden</em>'}
                    />
                    <AcReportLine
                      label={'Surseance van betaling'}
                      value={'<em>Er zijn geen registraties gevonden</em>'}
                    />
                    <AcReportLine
                      label={'WSNP'}
                      value={'<em>Er zijn geen registraties gevonden</em>'}
                    />
                  </AcColumn>
                </AcRow>
              </div>
            </AcColumn>
          </AcRow>

          <AcRow>
            <AcColumn xs={12} sm={10} md={8}></AcColumn>
          </AcRow>
        </AcColumn>

        <AcColumn xs={12} sm={6} md={4} className={'h-margin-bottom-25'}>
          <AcDirectAccess />
        </AcColumn>
      </AcRow>
    </AcContainer>
  );
};

export default inject('routing', 'store')(observer(AcReport));
