// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';

// Imports => Files
import DataInspectionLetter from '@assets/files/verhuursignaal-brief-inzage-gegevens.doc';
import PrivacyStatement from '@assets/files/verhuursignaal-privacy-statement-ggn-jan-2021.pdf';

export default [
	{
		uuid: AcUUID(),
		label: 'Brief verzoek inzage gegevens',
		url: DataInspectionLetter,
	},
	{
		uuid: AcUUID(),
		label: 'Privacy Statement',
		url: PrivacyStatement,
	},
];
