// Imports => React
import React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Constants
import { DEFAULT_ROUTE, KEYS, NAVIGATION_ITEMS } from '@constants';

// Imports => Atoms
import AcLogo from '@atoms/ac-logo/ac-logo.web';
import AcHamburger from '@atoms/ac-hamburger/ac-hamburger.web';

// Imports => Components
import { AcContainer } from '@atoms/ac-grid';
import AcNavigation from '@components/ac-navigation/ac-navigation.web';
import AcSubNavigation from '@components/ac-sub-navigation/ac-sub-navigation.web';

const _CLASSES = {
	MAIN: 'ac-header',
	NAV_VISIBLE: 'ac-header--navigation-visible',
	CONTAINER: {
		MAIN: 'ac-header__container',
		SUB: 'ac-header__sub-container',
	},
};

const AcHeader = ({ history, store, children }) => {
	const handleHamburgerClick = event => {
		store.ui.setState(
			KEYS.NAVIGATION,
			KEYS.VISIBLE,
			!store.ui.navigation.visible
		);
	};

	const navigateToDefaultRoute = () => {
		if (history && history.push) history.push(DEFAULT_ROUTE.path);
	};

	const getFilteredRoutes = () => {
		const routes = NAVIGATION_ITEMS;

		return routes.filter(route => {
			return route.forbidden ? store.auth.is_authorized : true;
		});
	};

	const getContainerClassNames = () => {
		return clsx(_CLASSES.CONTAINER.MAIN);
	};

	const getSubContainerClassNames = () => {
		return clsx(_CLASSES.CONTAINER.SUB);
	};

	const getStyleClassNames = () => {
		const { navigation } = store.ui;

		return clsx(
			_CLASSES.MAIN,
			navigation.visible && _CLASSES.NAV_VISIBLE
		);
	};

	return (
		<header className={getStyleClassNames()} id={getStyleClassNames()}>
			<AcContainer className={getSubContainerClassNames()}>
				<AcLogo callback={navigateToDefaultRoute} />
				<AcSubNavigation />
				<AcHamburger
					open={store.ui.navigation.visible}
					callback={handleHamburgerClick}
				/>
			</AcContainer>

			<AcContainer className={getContainerClassNames()}>
				<AcNavigation store={store} routes={getFilteredRoutes()} />
			</AcContainer>
		</header>
	);
};

export default withRouter(inject('store')(observer(AcHeader)));
