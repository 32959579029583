// Imports => React
import React, { useMemo, memo } from 'react';

// Imports => Config
import config from '@config';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';

const AcDirectAccess = ({ queue, callback }) => {
	const content = useMemo(() => {
		let result = [];

		if (config.site === KEYS.CHECK_SOCIAAL) {
			result = require('../../assets/_data/direct-access/direct-access.check-sociaal')
				.default;
		} else {
			result = require('../../assets/_data/direct-access/direct-access.verhuur-signaal')
				.default;
		}

		return result;
	}, [config.site]);

	return (
		<AcCard>
			<div className={'ac-card__content'}>
				<AcContainer>
					<AcRow className={'h-margin-bottom-10'}>
						<AcColumn>
							<AcHeading rank={2}>Direct naar</AcHeading>
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-bottom-10'}>
						<AcColumn>
							<ul className={'ac-direct-access__list'}>
								{content &&
									content.map(item => (
										<li
											className={'ac-direct-access__list__item'}
											key={item.uuid}
										>
											<a
												href={item.url}
												target={'_blank'}
												rel={'noopener nofollow noreferrer'}
												className={'ac-direct-access__link'}
											>
												{item.label}
											</a>
										</li>
									))}
							</ul>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</AcCard>
	);
};

export default memo(AcDirectAccess);
