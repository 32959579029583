// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';

// Imports => Files
import Leaflet from '@assets/files/check-sociaal-leaflets-producten-en-diensten.pdf';

export default [
	{
		uuid: AcUUID(),
		title: {
			rank: '1',
			text: 'Check Sociaal',
		},
	},
	{
		uuid: AcUUID(),
		title: {
			rank: '2',
			text: 'Vooraf weten met welke huurders u te maken krijgt',
		},
		content: `<p>Als woningcorporatie wilt u natuurlijk voorkomen dat het tot een ontruiming komt. Uw sociale taak is immers het realiseren van betaalbaar woongenot. Maar aan de andere kant: u hebt ook te maken met financiële doelstellingen. GGN begrijpt de spagaat waarmee woningcorporaties regelmatig te maken hebben. Daarom bieden wij met Check Sociaal een effectief instrument om deze spagaat zo veel mogelijk te voorkomen.</p><p><a href="${Leaflet}" target="_blank" rel="noopener nofollow noreferrer" class="ac-button h-margin-bottom-30">Meer weten? Bekijk de folder</a></p>`,
	},
	{
		uuid: AcUUID(),
		title: {
			rank: '3',
			text: 'Problemen voorkomen',
		},
		content:
			"<p>Met Check Sociaal controleert u eenvoudig online de betaalwaardigheid en de identiteit van uw nieuwe huurder. U krijgt een advies over een kandidaat huurder op persoonsniveau. Zo bent u in staat om (potentiële) 'probleemhuurders' tijdig een helpende hand te bieden en huurachterstanden en huisontruiming te voorkomen.</p>",
	},
	{
		uuid: AcUUID(),
		title: {
			rank: '3',
			text: 'Contactinformatie',
		},
		content:
			'<p>Wilt u ook Check Sociaal preventief in gaan zetten? Neem dan contact op met uw GGN accountmanager of neem direct contact op met <a href="mailto:sales@ggn.nl" target="_blank" rel="noopener nofollow noreferrer">sales@ggn.nl</a>.</p>',
	},
];
