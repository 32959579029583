// Imports => Constants
import { TITLES } from './titles.constants';

// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';

// Imports => Views
import { AcFaq, AcHome, AcReport, AcTermsConditions, AcVerify } from '@views';

export const PATHS = {
	AUTH: {
		LOGIN: '/inloggen',
		FORGOT_PASSWORD: '/wachtwoord-vergeten',
		RESET_PASSWORD: '/wachtwoord-resetten/:token',
		ACTIVATE: '/activeren',
	},
	FAQ: '/veelgestelde-vragen',
	HOME: '/',
	REPORT: '/rapportage',
	TERMS_CONDITIONS: '/algemene-voorwaarden',
	VERIFY: '/controleren',
};

export const ROUTES = {
	FAQ: {
		id: AcUUID(),
		name: 'Faq',
		label: TITLES.FAQ,
		path: PATHS.FAQ,
		component: AcFaq,
		roles: null,
		forbidden: false,
	},
	RESET_PASSWORD: {
		id: AcUUID(),
		name: 'Home',
		label: TITLES.HOME,
		path: PATHS.AUTH.RESET_PASSWORD,
		component: AcHome,
		roles: null,
		forbidden: false,
	},
	FORGOT_PASSWORD: {
		id: AcUUID(),
		name: 'Home',
		label: TITLES.HOME,
		path: PATHS.AUTH.FORGOT_PASSWORD,
		component: AcHome,
		roles: null,
		forbidden: false,
	},
	HOME: {
		id: AcUUID(),
		name: 'Home',
		label: TITLES.HOME,
		path: PATHS.HOME,
		component: AcHome,
		roles: null,
		forbidden: false,
	},
	REPORT: {
		id: AcUUID(),
		name: 'Report',
		label: TITLES.REPORT,
		path: PATHS.REPORT,
		component: AcReport,
		roles: null,
		forbidden: true,
	},
	TERMS_CONDITIONS: {
		id: AcUUID(),
		name: 'TermsConditions',
		label: TITLES.TERMS_CONDITIONS,
		path: PATHS.TERMS_CONDITIONS,
		component: AcTermsConditions,
		roles: null,
		forbidden: false,
	},
	VERIFY: {
		id: AcUUID(),
		name: 'Verify',
		label: TITLES.VERIFY,
		path: PATHS.VERIFY,
		component: AcVerify,
		roles: null,
		forbidden: true,
	},
};

export const NAVIGATION_ITEMS = [
	ROUTES.HOME,
	ROUTES.VERIFY,
	ROUTES.FAQ,
	ROUTES.TERMS_CONDITIONS,
];

export const SUB_NAVIGATION_ITEMS = [];

export const DEFAULT_ROUTE = ROUTES.HOME;
export const REDIRECT_ROUTE = ROUTES.HOME;
