// Imports => Vendor
import axios from 'redaxios';

// Imports => API
import AuthAPI from '@api/auth.api';
import ProfileApi from '@api/profile.api';
import VerifyAPI from '@api/verify.api';

export class API {
	constructor(config, Store) {
		this.Store = Store;

		const Client = axios.create(config.api);

		const DownloadClient = axios.create(config.download);

		this.auth = new AuthAPI({ Store, Client });
		this.profile = new ProfileApi({ Store, Client });
		this.verify = new VerifyAPI({ Store, Client, DownloadClient });
	}
}

export default API;
