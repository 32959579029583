import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { ICONS } from '@constants';

// Import => Utilities
import {
	AcHasNumericCharacter,
	AcHasMixedCharacters,
	AcHasSpecialCharacter,
	AcIsLongEnough,
} from '@utils';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-password-strength',
	CONTENT: 'ac-password-strength__content',
	LIST: {
		MAIN: 'ac-password-strength__list',
		ITEM: 'ac-password-strength__list__item',
		VALID: 'ac-password-strength__list__item--valid',
		INVALID: 'ac-password-strength__list__item--invalid',
		ICON: 'ac-password-strength__list__icon',
	},
};

const AcPasswordStrength = ({ password }) => {
	const [strength, setStrength] = useState({
		long_enough: false,
		numeric: false,
		special: false,
		mixed: false,
	});

	useEffect(() => {
		verifyPasswordStrength();
	}, [password]);

	const verifyPasswordStrength = useCallback(() => {
		const result = {
			long_enough: AcIsLongEnough(password, 8),
			numeric: AcHasNumericCharacter(password),
			special: AcHasSpecialCharacter(password),
			mixed: AcHasMixedCharacters(password),
		};

		setStrength(result);
	}, [password]);

	const getPasswordStrengthListItemClassNames = state => {
		return clsx(
			_CLASSES.LIST.ITEM,
			state && _CLASSES.LIST.VALID,
			!state && _CLASSES.LIST.INVALID
		);
	};

	const getPasswordStrengthListClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.MAIN);
	});

	const getPasswordStrengthIconClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.ICON);
	});

	const getPasswordStrengthContentClassNames = useMemo(() => {
		return clsx(_CLASSES.CONTENT);
	});

	const getPasswordStrengthClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	});

	return (
		<div className={getPasswordStrengthClassNames}>
			<AcRow>
				<AcColumn>
					<AcRichContent
						content={
							'<p>Om uw account te beschermen, zorg ervoor dat uw wachtwoord:</p>'
						}
						className={getPasswordStrengthContentClassNames}
					/>
				</AcColumn>
			</AcRow>

			<AcRow>
				<AcColumn>
					<ul className={getPasswordStrengthListClassNames}>
						<li
							className={getPasswordStrengthListItemClassNames(
								strength.long_enough
							)}
						>
							<AcIcon
								icon={
									strength.long_enough
										? ICONS.CHECKBOX_MARKED_CIRCLE_OUTLINE
										: ICONS.CLOSE_CIRCLE_OUTLINE
								}
								className={getPasswordStrengthIconClassNames}
							/>
							minimaal 8 karakters lang is
						</li>
						<li
							className={getPasswordStrengthListItemClassNames(
								strength.numeric
							)}
						>
							<AcIcon
								icon={
									strength.numeric
										? ICONS.CHECKBOX_MARKED_CIRCLE_OUTLINE
										: ICONS.CLOSE_CIRCLE_OUTLINE
								}
								className={getPasswordStrengthIconClassNames}
							/>
							minimaal 1 numeriek karakter heeft
						</li>
						<li
							className={getPasswordStrengthListItemClassNames(
								strength.special
							)}
						>
							<AcIcon
								icon={
									strength.special
										? ICONS.CHECKBOX_MARKED_CIRCLE_OUTLINE
										: ICONS.CLOSE_CIRCLE_OUTLINE
								}
								className={getPasswordStrengthIconClassNames}
							/>
							minimaal 1 speciaal karakter heeft
						</li>
						<li
							className={getPasswordStrengthListItemClassNames(strength.mixed)}
						>
							<AcIcon
								icon={
									strength.mixed
										? ICONS.CHECKBOX_MARKED_CIRCLE_OUTLINE
										: ICONS.CLOSE_CIRCLE_OUTLINE
								}
								className={getPasswordStrengthIconClassNames}
							/>
							een mix heeft van hoofdletters en kleine letters
						</li>
					</ul>
				</AcColumn>
			</AcRow>
		</div>
	);
};

export default memo(AcPasswordStrength);
