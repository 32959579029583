import API from '@api';

import AuthStore from '@stores/auth.store';
import ProfileStore from '@stores/profile.store';
import VerifyStore from '@stores/verify.store';

import UiStore from '@stores/ui.store';
import ToastersStore from '@stores/toasters.store';

class Store {
	constructor(config) {
		this.config = config;

		this.api = new API(config, this);

		this.auth = new AuthStore(this);
		this.profile = new ProfileStore(this);
		this.verify = new VerifyStore(this);

		this.ui = new UiStore(this);
		this.toasters = new ToastersStore(this);
	}
}

export default Store;
