import moment from 'moment';
import 'moment/locale/nl';

export const AcGetDaysInRange = (start, end, no_weekends) => {
	let days = [];
	let first_day = moment().startOf('week');
	let last_day = moment()
		.add(1, 'months')
		.endOf('week');

	let _start = start ? moment(start).startOf('week') : first_day;
	let _end = end ? moment(end).endOf('week') : last_day;

	while (_start.diff(_end) <= 0) {
		if (no_weekends) {
			// if (_start.isoWeekday() !== 6 && _start.isoWeekday() !== 7) {
			if (_start.isoWeekday() !== 7) {
				days.push({
					timestamp: _start.startOf('day').clone(),
					date: _start.format('DD-MM-YYYY'),
				});
			}
		} else {
			days.push({
				timestamp: _start.startOf('day').clone(),
				date: _start.format('DD-MM-YYYY'),
			});
		}

		_start.add(1, 'days');
	}

	return days;
};

export default AcGetDaysInRange;
