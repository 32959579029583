// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-rich-content',
};

// Component
const AcRichContent = ({ className, options, content, t }) => {
	const getContentOptions = () => {
		return options ? options : false;
	};

	const getStyleClassNames = () => {
		return clsx(_CLASSES.MAIN, className && className);
	};

	return (
		<div
			className={getStyleClassNames()}
			dangerouslySetInnerHTML={{
				__html: content,
			}}
		/>
	);
};

export default React.memo(AcRichContent);
