// Imports => React
import React from 'react';
import clsx from 'clsx';

const _CLASSES = {
	MAIN: 'ac-icon',
	PREFIX: 'ac-icon--',
};
// Component
const AcIcon = ({ icon, className }) => {
	const getStyleClassNames = () => {
		return clsx(
			_CLASSES.MAIN,
			icon && `${_CLASSES.PREFIX}${icon}`,
			className
		);
	};

	return <i className={getStyleClassNames()} />;
};

export default React.memo(AcIcon);
