let timer = null;

export const AcScrollTo = ($scroller, $target, offset, duration = 1000) => {
	clearTimeout(timer);

	let _scroller = $scroller;
	let start = _scroller.scrollTop,
		to = $target ? $target.getBoundingClientRect().top - offset : 0,
		change = to,
		currentTime = 0,
		increment = 20;

	const animateScroll = () => {
		currentTime += increment;
		let val = Math.easeInOutQuad(currentTime, start, change, duration);

		_scroller.scrollTop = val;

		if (currentTime < duration) {
			timer = setTimeout(animateScroll, increment);
		}
	};

	animateScroll();
};

export default AcScrollTo;
